import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Paginate = (props) => {
    const pageArray = useMemo(() => {
        return new Array(props.pageLimit).fill(1);
    }, [props.pageLimit]);

    return (
        <nav className="pagination">
            <ul>
                { pageArray.map((item, index) => {
                    return (
                        <li className={index+1 === props.page ? 'link active': 'link'} key={`pagination-page-${index}`}>
                            <Link to={`?page=${index + 1}`}>{ index + 1}</Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}

export default Paginate;
