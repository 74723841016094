import React from 'react';
import Admin from '../layouts/admin/Admin';
import DashboardStats from '../components/dashboard-stats/dashboard-stats';
import Title from '../components/title/title';

export default function Home () {
    return (
        <Admin>
            <Title>Dashboard</Title>
            <DashboardStats />
        </Admin>
    )
}
