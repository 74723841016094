import React from 'react';
import './style.scss';
import Navbar from '../../components/navbar/navbar';
// import Sidebar from '../../components/sidebar/sidebar';
import { Container, Grid, Rail } from 'semantic-ui-react'

const Admin = (props) => {
    return (
        <Grid centered columns={2}>
            <Grid.Column>
                <Rail position='left'>
                    <header>
                        <Navbar/>
                    </header>
                </Rail>
                <Container className="page-wrapper">
                    <div className="page">
                        <main>
                            <div className="content">
                                { props.children }
                            </div>
                        </main>
                    </div>
                </Container>
            </Grid.Column>
        </Grid>
    )
}

export default Admin;
