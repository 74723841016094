import React from 'react';
import './style.scss';
import { view } from '@risingstack/react-easy-state';
import session from '../../stores/session';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react'

const Navbar = view(() => {
    return [
        <br />,
        <Menu vertical>
            {
                session.user.name ? <>
                    <Link to="/"><Menu.Item name="Dashboard" /></Link>
                    <Menu.Item>
                        <Menu.Header>Platform</Menu.Header>
                        <Menu.Menu>
                            <Link to="/vendors"><Menu.Item name="Vendors" /></Link>
                            <Link to="/highstreets"><Menu.Item name="Highstreets" /></Link>
                            <Link to="/categories"><Menu.Item name="Categories" /></Link>
                            <Link to="/tags"><Menu.Item name="Tags" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Customer Service</Menu.Header>
                        <Menu.Menu>
                            <Link to="/customers"><Menu.Item name="Customers" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Logistics</Menu.Header>
                        <Menu.Menu>
                            <Link to="/logistics"><Menu.Item name="Logistics" /></Link>
                            <Link to="/deliveryslots"><Menu.Item name="Delivery Slots" /></Link>
                            <Link to="/export"><Menu.Item name="Export" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Bids</Menu.Header>

                        <Menu.Menu>
                            <Link to="/bids"><Menu.Item name="Bids" /></Link>
                            <Link to="/bidVendors"><Menu.Item name="Bid Vendors" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Coupons</Menu.Header>

                        <Menu.Menu>
                            <Link to="/coupons"><Menu.Item name="Coupons" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Promotions</Menu.Header>

                        <Menu.Menu>
                            <Link to="/promotions"><Menu.Item name="Promotions" /></Link>
                        </Menu.Menu>
                    </Menu.Item>

                    <Menu.Item>
                        <Menu.Header>Misc.</Menu.Header>

                        <Menu.Menu>
                            <Link to="/variables"><Menu.Item name="Variables" /></Link>
                        </Menu.Menu>
                    </Menu.Item>


                    <Menu.Menu position="right">
                        {/* {JSON.stringify(session)} */}
                        <Menu.Header>{`Logged in as ${session.user.name}`}</Menu.Header>
                        <Menu.Menu>
                            { session.user.name ?
                                <Link to='/logout'>
                                    <Menu.Item name={`Log Out`} />
                                </Link>
                            : null }
                        </Menu.Menu>

                    </Menu.Menu>
                </> : <>
                    <Link to="/login"><Menu.Item name="Login" /></Link>
                </>
            }
        </Menu>
    ]
});

export default Navbar;
