import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import request from '../helpers/request';
import Paginate from '../components/paginate/paginate';
import { withRouter, Link } from 'react-router-dom';
import Title from '../components/title/title';
import VendorsComponent from '../components/vendors/vendors';
import { Button } from 'semantic-ui-react';

class Vendors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vendors: [],
            page: 0,
            pageLimit: 0,
        }
    }

    componentDidMount() {
        this.updateComponent(this.props.location.search);

        this.props.history.listen((location) => {
            this.updateComponent(location.search);
        });
    }

    update = (search) => {

        this.params = new URLSearchParams(search);

        if (this.params.has('page')) {
            this.getPage(this.params.get('page'));
        } else {
            this.getPage(1);
        }
    }

    updateComponent = (search) => {
        this.update(search)
    }

    getPage = (page) => {
        request(true).get(`/vendors?page=${page}`).then(r => {
            this.setState({
                vendors: r.data.data,
                page: parseInt(r.data.meta.page),
                pageLimit: r.data.meta.total_pages,
                oldSearch: this.props.location.search
            });
        }).catch(e => {
            console.log(e.response);
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Vendors</Title>
                <Link to="/vendors/new"><Button color="green">New Vendor</Button></Link>
                <VendorsComponent update={this.update} vendors={ this.state.vendors }/>
                <Paginate page={this.state.page} pageLimit={this.state.pageLimit}/>
            </Admin>
        );
    }
}

export default withRouter(Vendors);
