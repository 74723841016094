import React from 'react'
import Admin from '../layouts/admin/Admin'
import { Header } from 'semantic-ui-react'
import VariablesList from '../components/variables/variables-list'

export default function Variables() {
    return (
        <Admin>
            <Header>Variables</Header>
            <VariablesList />
        </Admin>
    )
}
