import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function DeliverySlots() {

  const [slots, setSlots] = useState([])

  const updateSlots = () => {
    request(true).get('/deliveryslots').then(e => {
      setSlots(e.data)
    })
  }

  useEffect(() => {
    updateSlots()
  }, [])

  const newDeliveryDay = () => {
    request(true).post('/deliveryslots', {
      day: 'New Day',
      slots: []
    }).then(e => {
      if (e.status === 200) {
        updateSlots()
        return alert('New Delivery Day Created!')
      }
    })
  }

  return (
    <div>
      <Admin>
        <Header>Delivery Slot Days</Header>
        <Button onClick={() => newDeliveryDay()} color="green">New Delivery Day</Button>
        <Grid columns={3}>
          {
            slots.map(slot => {
              return <Grid.Column>
                <DeliverySlot slot={slot} update={updateSlots} />
              </Grid.Column>
            })
          }
        </Grid>
      </Admin>
    </div>
  )
}


function DeliverySlot({ slot, update }) {
  const del = () => {
    const c = window.confirm(`Do you want to delete ${slot.day}?`)

    if (c) {
      request(true).delete(`/deliveryslots/${slot._id}`).then(e => {
        if (e.status === 200) {
          update()
          return alert('Deleted!')
        }
        return alert(e.statusText)
      })
    }
  }

  const copy = () => {
    const c = window.confirm(`Do you want to copy ${slot.day}?`)

    if (c) {
      request(true).post(`/deliveryslots/${slot._id}/copy`).then(e => {
        if (e.status === 200) {
          update()
          return alert('Coppied!')
        }
        return alert(e.statusText)
      })
    }
  }

  return (
    <Segment>
      <p>Day: {slot.day}</p>
      <p>Total Slots: {slot.slots.length}</p>
      <Link to={`/deliveryslots/${slot._id}`}>
        <Button icon="edit" color="green"></Button>
      </Link>
      <Button onClick={() => del()} icon="trash" color="red"></Button>
      <Button onClick={() => copy()} icon="copy" color="blue"></Button>
    </Segment>
  )
}