import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import request from '../helpers/request';
import Paginate from '../components/paginate/paginate';
import { withRouter, Link } from 'react-router-dom';
import Title from '../components/title/title';
import ProductsComponent from '../components/products/products';
import { Button } from 'semantic-ui-react';

class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            page: 0,
            pageLimit: 0,
        }
    }

    componentDidMount() {
        this.updateComponent(this.props.location.search);

        this.props.history.listen((location) => {
            this.updateComponent(location.search);
        });
    }

    updateComponent = (search) => {
        this.params = new URLSearchParams(search);

        if (this.params.has('page')) {
            this.getPage(this.params.get('page'));
        } else {
            this.getPage(1);
        }
    }

    getPage = (page) => {
        request(true).get(`/products?page=${page}&vendor=${this.props.match.params.vendorId}`).then(r => {
            this.setState({
                products: r.data.data,
                page: parseInt(r.data.meta.page),
                pageLimit: r.data.meta.total_pages,
                oldSearch: this.props.location.search
            });
        }).catch(e => {
            console.log(e.response);
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Products</Title>
                <Link to={`/vendors/${this.props.match.params.vendorId}/products/new`}><Button color="green">New Product</Button></Link>
                <ProductsComponent products={ this.state.products } vendor={this.props.match.params.vendorId} />
                <Paginate page={this.state.page} pageLimit={this.state.pageLimit}/>
            </Admin>
        );
    }
}

export default withRouter(Products);
