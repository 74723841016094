import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import request from '../helpers/request';
import { withRouter, Link } from 'react-router-dom';
import TagsComponent from '../components/tags/tags';
import Title from '../components/title/title';
import { Button, Icon } from 'semantic-ui-react'

class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            page: 0,
            pageLimit: 0,
        }
    }

    componentDidMount() {
        this.getPage();
    }

    getPage = () => {
        request(true).get(`/tags`).then(r => {
            this.setState({
                tags: r.data,
            });
        }).catch(e => {
            console.log(e);
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Tags</Title>
                <Link to="/tags/new">
                    <Button animated='vertical'>
                        <Button.Content hidden><Icon name="add circle"></Icon></Button.Content>
                        <Button.Content visible>New Tag</Button.Content>
                    </Button>
                </Link>
                <TagsComponent tags={this.state.tags}/>
            </Admin>
        );
    }
}

export default withRouter(Tags);
