import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter } from 'react-router-dom';
import request from '../helpers/request';
import EditSubproductComponent from '../components/products/subproducts/editSubproduct';

class EditSubproduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            productId: null,
            loadedProductId: null,
            subProductId: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const match = props.match

        if (match.params.variant === "new") {
            return {
                product: state.product,
                productId: match.params.id,
                loadedProductId: state.loadedProductId,
                variant: null,
            }
        } else {
            return {
                product: state.product,
                productId: match.params.id,
                loadedProductId: state.loadedProductId,
                variant: match.params.variant
            }
        }
    }

    componentDidMount() {
        if (this.state.productId) {
            this.loadProduct();
        }
    }

    componentDidUpdate() {
        if (this.state.productId !== this.state.loadedProductId) {
            this.loadProduct();
        }
    }

    loadProduct() {
        request(true).get(`/products/${this.state.productId}`).then(r => {
            this.setState({
                product: r.data,
                loadedProductId: this.state.productId
            })
        }).catch(r => {
            alert('There was an error', r)
            this.props.history.push('/vendors/' + this.props.product.vendor);
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Variant</Title>
                { this.state.product ?
                    <EditSubproductComponent product={this.state.product} variantId={this.state.variant}/>
                : '' }
            </Admin>
        );
    }
}

export default withRouter(EditSubproduct);
