import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import request from '../../helpers/request';

const Product = (props) => {

    const history = useHistory();

    const deleteProduct = () => {
        const c = window.confirm(`Delete ${props.product.name}?`)
        if (c) {
            request(true).delete(`/products/${props.product._id}`).then(r => {
                history.push('/products');
            }).catch(e => {
                console.log(e.response);
            })
        }
    }

    return (
        <div className="product">
            <div className="name">
                { props.product.name }
            </div>
            <div className="actions grid grid-2 grid-gap-1">
                <Link to={`/vendors/${props.vendor}/products/${props.product._id}`}>
                    <Button>Edit</Button>
                </Link>
                <Button color="red" onClick={deleteProduct}>
                    Delete
                </Button>
            </div>
        </div>
    );
}

export default Product;
