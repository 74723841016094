import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react'
import request from '../../helpers/request';

const Tag = (props) => {

    const deleteTag = () => {
        let confirm = window.confirm(`Delete Tag "${props.tag.name}"?`)
        console.log(confirm)
        if (confirm) {
            request(true).delete(`/tags/${props.tag._id}`).then(r => {
                window.location.reload()
            }).catch(e => {
                console.log(e.response);
            })
        }
    }

    return (
        <>
            <Table.Cell>{ props.tag.name } </Table.Cell>
            <Table.Cell> <i className={`far fa-${props.tag.icon}`}></i> ({ props.tag.icon }) </Table.Cell>
            <Table.Cell>
                <Link to={`/tags/${props.tag._id}`} className="action">
                    <Icon name="pencil" />
                </Link>
                <Icon link color="red" name="trash" onClick={deleteTag} />
            </Table.Cell>
        </>
    )
}

export default Tag;
