import React, { useState } from 'react'
import { useMemo } from 'react'
import { Button, Input, Table } from 'semantic-ui-react'
import Paginate2 from '../components/paginate2/paginate2'
import Title from '../components/title/title'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function Customers() {
    const [page, setPage] = useState(1)
    const [email, setEmail] = useState('')
    const [customers, setCustomers] = useState({
        data: [],
        meta: {},
    })
    useMemo(() => {
        request(true).get(`/customers?page=${page}&email=${email}`).then(e => setCustomers(e.data));
    }, [page, email])
    const loginAsCustomer = (id) => {
        request(true).post(`/customers/${id}/emulate`).then(e => window.open(e.data.url, '_blank'))
    }
    return (
        <Admin>
            <Title>Customers</Title>
            <Input type="email" placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {
                        customers.data.map((c) => {
                            return (<Table.Row key={`customer-record-${c._id}`}>
                                <Table.Cell>{c.name}</Table.Cell>
                                <Table.Cell>{c.email}</Table.Cell>
                                <Table.Cell>
                                    <Button.Group>
                                        { c.stripeCustomerId ? <Button onClick={() => window.open(`${process.env.CI !== undefined ? 'https://dashboard.stripe.com/customers' : 'https://dashboard.stripe.com/test/customers'}/${c.stripeCustomerId}`, '_blank')} size="small"><span className="fab fa-stripe fa-2x"></span></Button> : null }
                                        <Button onClick={() => loginAsCustomer(c._id)} size="small">Login As</Button>
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>)
                        })
                    }
                </Table.Body>
            </Table>

            <Paginate2 data={customers.meta} goToPage={setPage} />
            
        </Admin>
    )
}
