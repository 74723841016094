import React, { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import { Button } from 'semantic-ui-react'
import request from '../../helpers/request'

import './image-manager.scss'

export default function ImageManager(props) {
    const uploadRef = React.useRef()
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])

    const updateImages = useCallback(() => {
        request(true).get(props.endpoint).then(e => setImages(e.data))
    }, [props.endpoint])

    useEffect(() => {
        updateImages()
    }, [updateImages])

    const uploadImage = (e) => {
        console.log('CAPTURED UPLOAD')

        setLoading(true)

        const file = e.target.files[0]
        const fr = new FileReader()

        fr.onload = (ev => {
            console.log('PROCESSED BASE64')
            console.log('UPLOADING IMAGE')
            request(true).post(props.endpoint, {
                image: fr.result
            }).then(data => {
                if (data.status !== 200) {
                    setLoading(false)
                    console.log('UPLOADING FAILED', e)
                    uploadRef.current.value = ""
                }
                uploadRef.current.value = ""

                setLoading(false)
                updateImages()
                console.log('UPLOAD COMPLETE')
            }).catch(data => {
                updateImages()
                setLoading(false)
                uploadRef.current.value = ""
                alert(e.response.data)
            })
        })

        console.log('READING FILE')
        fr.readAsDataURL(file)
    }

    return (
        <div className="image-manager">
            <div>
                <Button 
                color="green" 
                onClick={(e) => {e.preventDefault(); uploadRef.current.click(); }} 
                loading={loading} 
                disabled={loading}>
                    Upload Image
                </Button>
                <input hidden type="file" ref={uploadRef} onChange={uploadImage} accept="image/*" />
            </div>
            <div className="images">
                {
                    images && images.length ? images.map(image => {
                        return (image ? <ImageManagerItem update={updateImages} key={`${props.endpoint}-${image._id}`} endpoint={props.endpoint} image={image} /> : null)
                    }) : null
                }
            </div>
        </div>
    )
}

function ImageManagerItem(props) {
    const deleteImage = (e) => {
        e.preventDefault()

        const conf = window.confirm('Delete this image?')

        if (conf) {
            request(true).delete(`${props.endpoint}/${props.image._id}`).then(e => {
                if (e.status === 200) alert('Deleted!')
                if (e.status !== 200) alert(`Deletion Failed! ${JSON.stringify(e.data)}`)
                props.update()
            }).catch(e => {
                alert(e.response.data)
            })
        }
    }

    return (
        <div>
            <img alt={""} src={props.image.url} />
            <div className="controls">
                <Button.Group size="mini">
                    {/* <Button icon="arrow left"></Button> */}
                    <Button icon="trash" color="red" onClick={deleteImage}></Button>
                    {/* <Button icon="arrow right"></Button> */}
                </Button.Group>
            </div>
        </div>
    )
}