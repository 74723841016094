import React from 'react';

const Select = (props) => {
    return (
        <div className="select-wrapper">
            <select { ...props } defaultValue='default'>
                <option key={ Math.random() } value='default' selected>Select a { props.name }</option>
                { props.options.map((item, index) => {
                    return (
                        <option key={ Math.random() } value={item.value}>{ item.label }</option>
                    )
                })}
            </select>
        </div>
    );
}

export default Select;
