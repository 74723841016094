import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter, matchPath } from 'react-router-dom';
import EditHighstreetComponent from '../components/highstreets/editHighstreet';
import request from '../helpers/request';

class EditHighstreet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highstreet: null,
            highstreetId: null,
            loadedHighstreetId: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const match = matchPath(props.history.location.pathname, {
            path: '/highstreets/:id'
        });

        if (match.params.id === "new") {
            return {
                highstreet: {
                    name: '',
                    geo: {
                        long: '',
                        lat: ''
                    }
                },
                highstreetId: null,
                loadedHighstreetId: null
            }
        } else {
            return {
                highstreet: state.highstreet,
                highstreetId: match.params.id,
                loadedHighstreetId: state.loadedHighstreetId
            }
        }
    }

    componentDidMount() {
        if (this.state.highstreetId) {
            this.loadHighstreet();
        }
    }

    componentDidUpdate() {
        if (this.state.highstreetId !== this.state.loadedHighstreetId) {
            this.loadHighstreet();
        }
    }

    loadHighstreet() {
        request(true).get(`/highstreets/${this.state.highstreetId}`).then(r => {
            this.setState({
                highstreet: r.data,
                loadedHighstreetId: this.state.highstreetId
            })
        }).catch(r => {
            this.props.history.push('/highstreets');
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Highstreet</Title>
                { this.state.highstreet ?
                    <EditHighstreetComponent highstreet={ this.state.highstreet } highstreetId={ this.state.highstreetId }/>
                : '' }
            </Admin>
        );
    }
}

export default withRouter(EditHighstreet);
