const DeliveryStatus = (input) => {
  let colour = 'grey'
  if (input === "Processing") {
    colour = 'orange'
  }

  return {
    label: input,
    colour: colour
  }
}

module.exports = DeliveryStatus