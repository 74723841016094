import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import request from '../../helpers/request';

const Highstreet = (props) => {

    const history = useHistory();

    const deleteHighstreet = () => {
        request(true).delete(`/highstreets/${props.highstreet._id}`).then(r => {
            history.push('/highstreets');
        }).catch(e => {
            console.log(e.response);
        })
    }

    return (
        <div className="highstreet">
            <div className="name">
                { props.highstreet.name }
            </div>
            <div className="actions grid grid-2 grid-gap-1">
                <Link to={`/highstreets/${props.highstreet._id}`} className="action">
                    Edit
                </Link>
                <div className="action" onClick={deleteHighstreet}>
                    Delete
                </div>
            </div>
        </div>
    );
}

export default Highstreet;
