import React from 'react'
import { Button, Header } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function DangerZone() {
    const resetAllSubscriptions = () => {
        if (window.confirm('Make sure you\'re not in production!')) {
            if (window.confirm('Are you sure you want to reset all subscription last order times, this will probably run all subscription jobs')) {
                if (window.confirm('final confirm, good luck')) {
                    request(true).post('/admin/reset-subscriptions').then(() => {
                        window.alert('Reset all subscriptions complete')
                    }).catch(() => {
                        window.alert('Reset all subscriptions failed')
                    }).finally(() => {
                        window.alert('Reset all subscriptions')
                    })
                }
            }
        }
    }
    const resetPausedSubscriptions = () => {
        if (window.confirm('Make sure you\'re not in production!')) {
            if (window.confirm('Are you sure you want to reset all subscription last order times, this will probably run all subscription jobs')) {
                if (window.confirm('final confirm, good luck')) {
                    request(true).post('/admin/reset-subscriptions-paused').then(() => {
                        window.alert('Reset paused subscriptions complete')
                    }).catch(() => {
                        window.alert('Reset paused subscriptions failed')
                    }).finally(() => {
                        window.alert('Reset paused subscriptions')
                    })
                }
            }
        }
    }
    const resetCancelledSubscriptions = () => {
        if (window.confirm('Make sure you\'re not in production!')) {
            if (window.confirm('Are you sure you want to reset all subscription last order times, this will probably run all subscription jobs')) {
                if (window.confirm('final confirm, good luck')) {
                    request(true).post('/admin/reset-subscriptions-cancelled').then(() => {
                        window.alert('Reset cancelled subscriptions complete')
                    }).catch(() => {
                        window.alert('Reset cancelled subscriptions failed')
                    }).finally(() => {
                        window.alert('Reset cancelled subscriptions')
                    })
                }
            }
        }
    }
    return (
        <Admin>
            <Header>DANGER ZONE</Header>
            <h4 style={{color: '#db2828'}}>ALL DANGEROUS</h4>
            <h4 style={{color: '#db2828'}}>PROBABLY DON'T WANT TO USE ANY OF THIS IN PRODUCTION</h4>
            <Button onClick={resetAllSubscriptions} color="red">RESET ALL SUBSCRIPTION LAST ORDER TIME</Button>
            <Button onClick={resetPausedSubscriptions} color="red">RESET SUBSCRIPTION PAUSED UNTIL</Button>
            <Button onClick={resetCancelledSubscriptions} color="red">RESET SUBSCRIPTION CANCELLED</Button>
        </Admin>
    )
}
