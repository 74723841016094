import { store } from '@risingstack/react-easy-state'
import request from '../helpers/request';

const session = store({
    token: localStorage.getItem('user-token'),
    user: {
        name: null,
        email: null,
    },
    fetch: () => {
        return request(true).get('/self').then(r => {
            session.user = {
                name: r.data.name,
                email: r.data.email,
                admin: r.data.admin
            }
        }).catch(e => {
            session.reset();
        });
    },
    reset: () => {
        session.token = null;
        localStorage.removeItem('user-token');
        session.user = {
            name: null,
            email: null
        };
    },
    setToken: (token) => {
        session.token = token;
        localStorage.setItem('user-token', token);
    },
    isAlive: () => {
        if (session.token) {
            if (session.user.name) {
                return true;
            } else {
                return session.fetch();
            }
        }

        return false;
    },

    loginControl: (bool) => {}
});

export default session;
