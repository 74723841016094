import React, { useCallback, useEffect } from 'react';
import request from '../../helpers/request';
import { useHistory } from 'react-router-dom';
import {Button, Input} from 'semantic-ui-react'


const EditHighsteet = (props) => {
    const [formState, setFormState] = React.useState({
        name: '',
        'geo.lat': '',
        'geo.long': '',
        live: false
    });

    const onChange = useCallback((event) => {
        let value
        const target = event.target

        if (target.type === 'checkbox') {
            value = target.checked
        } else {
            value = target.value
        }

        setFormState({
            ...formState,
            [target.name]: value
        });
    }, [formState, setFormState]);

    const history = useHistory();

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        if (props.highstreetId === null) {
            request(true).post('/highstreets', {
                name: formState.name,
                geo: {
                    lat: formState['geo.lat'],
                    long: formState['geo.long']
                },
                live: formState.live || false
            }).then(r => {
                window.alert(r.statusText)
                history.push('/highstreets');
            }).catch(e => {
                console.log(e.response);
            });
        } else {
            request(true).patch(`/highstreets/${props.highstreetId}`, {
                name: formState.name,
                geo: {
                    lat: formState['geo.lat'],
                    long: formState['geo.long']
                },
                live: formState.live || false
            }).then(r => {
                window.alert(r.statusText)
                history.push('/highstreets');
            }).catch(e => {
                console.log(e.response);
            })
        }
    }, [props.highstreetId, formState, history]);

    useEffect(() => {
        setFormState({
            name: props.highstreet.name,
            'geo.lat': props.highstreet.geo ? props.highstreet.geo.coordinates[0] : null,
            'geo.long': props.highstreet.geo ? props.highstreet.geo.coordinates[1] : null,
            live: props.highstreet.live ? props.highstreet.live : false
        });
    }, [setFormState, props.highstreet]);

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-1 grid-gap-1">
                <label>Name</label>
                <Input name="name" type="text" placeholder="Name" value={ formState.name } onChange={ onChange }/>
                <label>Latitude</label>
                <Input name="geo.lat" type="number" step="any" placeholder="Latitude" defaultValue={ formState['geo.lat'] } onChange={ onChange }/>
                <label>Longitude</label>
                <Input name="geo.long" type="number" step="any" placeholder="Longitude" defaultValue={ formState['geo.long'] } onChange={ onChange }/>
                <label>Live?</label>
                <Input style={{justifySelf: 'start'}} type="checkbox" checked={ formState['live'] } onChange={ onChange } name="live" />
                <Button>{ props.highstreetId ? 'Update' : 'Create' }</Button>
            </div>
        </form>
    );
}

export default EditHighsteet;
