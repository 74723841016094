import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { Button, Form, Grid, Label, LabelGroup, Modal, Segment, TextArea } from 'semantic-ui-react'
import DeliveryStatus from '../helpers/deliveryStatus'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'
import { useCallback } from 'react'

export default function LogisticsPage() {
  const [queryFrom, setQueryFrom] = useState(null)
  const [queryTo, setQueryTo] = useState(null)

  const [deliverySlots, setDeliverySlots] = useState([])
  const [deliverySlotId, setDeliverySlotId] = useState(null)
  const [deliveryId, setDeliveryId] = useState(null)
  const [orderId, setOrderId] = useState(null)

  const [editDelivery, setEditDelivery] = useState(false)

  const [drivers, setDrivers] = useState([])

  const [showEditNotes, setShowEditNotes] = useState(false)

  const getDeliverySlots = useCallback(() => {
    const options = new URLSearchParams()
    if (queryFrom) options.append('from', queryFrom)
    if (queryTo) options.append('to', queryTo)
    request(true).get(`/admin/logistics/delivery-slots?${options.toString()}`).then(e => {
      if (e.status === 200) {
        setDeliverySlots(e.data)
      }
    })
  }, [queryFrom, queryTo])

  const currentDeliverySlot = useMemo(() => {
    setDeliveryId(null)
    setOrderId(null)
    if (deliverySlotId) {

      return deliverySlots.find(_ => _._id === deliverySlotId);

    } else {

      if (queryFrom || queryTo) {

        if (deliverySlots && deliverySlots.length && deliverySlots.length > 0) {
          const de = deliverySlots.reduce((p, c) => {console.log('c', c, c.deliveries);p = p.concat(c.deliveries);return p;}, [])
          console.log('de', de)
          return {
            deliveries: de
          }
        } else {
          return null
        }

      } else {
        return null
      }

    }
  }, [deliverySlotId, deliverySlots, queryFrom, queryTo])

  const currentDelivery = useMemo(() => {
    setOrderId(null)
    console.log(deliverySlots)
    return deliveryId ? currentDeliverySlot.deliveries.find(_ => _._id === deliveryId) : null
  }, [deliveryId, deliverySlots, currentDeliverySlot])

  const currentOrder = useMemo(() => {
    console.log(deliverySlots)
    return orderId ? currentDelivery.orders.find(_ => _._id === orderId) : null
  }, [orderId, deliverySlots, currentDelivery])

  const updateDelivery = (status, driver) => {
    setEditDelivery(false)
    request(true).post(`/admin/logistics/delivery-slots/${currentDeliverySlot._id}/${currentDelivery._id}`, {
      status,
      driver
    }).then(res => {
      if (res.status === 200) {
        getDeliverySlots();
      } else {
        window.alert(`Error! ${res.statusText} ${JSON.stringify(res.data)}`)
      }
    }).catch((err) => {
      window.alert(`Error! ${JSON.stringify(err ? err.response : err)}`)
    })
  }

  const getDrivers = useCallback(() => {
    request(true).get('/drivers').then(e => {
      if (e.status === 200) {
        setDrivers(e.data)
      }
    })
  }, [])

  useEffect(() => {
    console.log(2)
    getDeliverySlots()
    getDrivers()
  }, [getDeliverySlots, getDrivers])

  useEffect(() => {
    console.log(1)
    getDeliverySlots()
  }, [queryFrom, queryTo, getDeliverySlots])

  return [
    <Admin>
      <h1>Logistics</h1>

      <Grid columns={4}>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Future Delivery Slot</label>
              <select onChange={e => setDeliverySlotId(e.target.value || null)}>
                <option value={""}>Select One</option>
                {
                  deliverySlots.map(slot => {
                    return (
                      <option value={slot._id}>{slot.day} ({slot.time}) +{slot.deliveryCount}</option>
                    )
                  })
                }
              </select>
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>From</label>
              <input type="date" onChange={(e) => { setQueryFrom(e.target.value) }} />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>To (and including)</label>
              <input type="date" onChange={(e) => { setQueryTo(e.target.value) }} />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column verticalAlign="middle" textAlign="right">
          <Button color="green" onClick={getDeliverySlots}><i className="far fa-sync-alt"></i></Button>
        </Grid.Column>
      </Grid>

      <Segment>
        <Grid columns={3} divided>
            <Grid.Column>
              <h2>Deliveries</h2>
              {
                currentDeliverySlot ? currentDeliverySlot.deliveries.map(delivery => {
                  const ds = DeliveryStatus(delivery.status);
                  return (
                    <Segment key={delivery._id} color={ds.colour} onClick={() => setDeliveryId(delivery._id)}>
                      <LabelGroup>
                        <Label color={ds.colour} ribbon>{ds.label}</Label>
                        {delivery.driver ? <Label color="purple">Driver Assigned</Label> : null}
                      </LabelGroup>
                      
                      <span></span>
                      <h3>{delivery.customer.name}</h3>
                      <p>{delivery.orders.length} Order(s)</p>
                      <div>
                        {
                          Object.keys(delivery.customer.addresses[0]).map(ty => {
                            if (ty === '_id') return <></>
                            return (
                              <p><strong>{ty}</strong>: {delivery.customer.addresses[0][ty]}</p>
                            )
                          })
                        }
                      </div>
                      <Button color="red" compact onClick={() => setEditDelivery(true)}>Update Delivery</Button>
                      <Button color="orange" compact onClick={() => setShowEditNotes(true)}>Notes ({delivery.notes.length})</Button>
                    </Segment>
                  )
                }) : null
              }
            </Grid.Column>
            <Grid.Column>
              <h2>Orders</h2>
              {
                currentDelivery ? currentDelivery.orders.map(order => {
                  return (
                    <Segment color="green" onClick={() => setOrderId(order._id)}>
                      <Label color="pink" ribbon>{order.order.source} Order</Label>
                      <h3>#{order.order._id.substr(-6)}</h3>
                      <p>{order.items.length} Items</p>
                      <p><strong>Total</strong>: {order.order.price.total}</p>
                      <p><strong>Paid for at</strong>: {moment(order.order.paymentConfirmedAt).format('Do MMM HH:MM')}</p>
                    </Segment>
                  )
                }) : null
              }
            </Grid.Column>
            <Grid.Column>
              <h2>Details</h2>
              {
                currentOrder ? <OrderRow order={currentOrder} /> : null
              }
            </Grid.Column>
        </Grid>
      </Segment>
    </Admin>,
    <EditDelivery 
      editDelivery={editDelivery} 
      setEditDelivery={setEditDelivery} 
      currentDelivery={currentDelivery} 
      updateDelivery={updateDelivery}
      drivers={drivers} />,
    <DeliveryNote currentDeliverySlot={currentDeliverySlot} currentDelivery={currentDelivery} showEditNotes={showEditNotes} setShowEditNotes={setShowEditNotes} getDeliverySlots={getDeliverySlots} />
  ]
}

function EditDelivery({ editDelivery, setEditDelivery, currentDelivery, updateDelivery, drivers }) {
  return (
    <Modal 
    centered={false}
    open={editDelivery}
    size="mini"
    closeIcon={true}
    onClose={() => setEditDelivery(false)}
    >
      <Modal.Header>
        <h1>Update Delivery</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field inline={false}>
            <label>Status</label>
            <select value={currentDelivery ? currentDelivery.status : null} onChange={(e) => updateDelivery(e.target.value)}>
              <option>Processing</option>
              <option>Out for Delivery</option>
              <option>Dispute</option>
              <option>Refunded</option>
              <option>Delivered</option>
            </select>
          </Form.Field>
          <Form.Field inline={false}>
            <label>Assigned Driver</label>
            <select value={currentDelivery ? currentDelivery.driver : null} onChange={(e) => updateDelivery(undefined, e.target.value === "null" ? null : e.target.value)}>
              <option value={"null"}>Select one</option>
              {
                drivers.map(driver => {
                  return (
                    <option value={driver._id}>{driver.name} ({driver.user.name})</option>
                  )
                })
              }
            </select>
          </Form.Field>
          {/* <Form.Field>
            <Button color="green">Update Delivery</Button>
          </Form.Field> */}
        </Form>
      </Modal.Content>
    </Modal>
  )
}

function DeliveryNote({ currentDeliverySlot, currentDelivery, showEditNotes, setShowEditNotes, getDeliverySlots }) {
  const [note, setNote] = useState(null)
  const [notes, setNotes] = useState([])

  const getNotes = useCallback(() => {
    if (!currentDeliverySlot || !currentDelivery) return []
    return request(true).get(`/admin/logistics/delivery-slots/${currentDeliverySlot._id}/${currentDelivery._id}/notes`).then(e => setNotes(e.data))
  }, [currentDelivery, currentDeliverySlot])

  useEffect(() => {
    console.log(currentDelivery, currentDeliverySlot)
    getNotes()
  }, [currentDelivery, currentDeliverySlot, getNotes])

  const addNoteToDelivery = () => {
    request(true).post(`/admin/logistics/delivery-slots/${currentDeliverySlot._id}/${currentDelivery._id}/notes`, {
      note
    }).then(e => {
      if (e.status === 200) {
        setNote(null)
        getNotes()
        setShowEditNotes(false)
        getDeliverySlots()
      }
    })
  }

  return (
    <Modal open={showEditNotes} size="tiny" closeIcon={true} centered={false} onClose={() => setShowEditNotes(false)} >
      <Modal.Header>
        <h1>Delivery Notes</h1>
      </Modal.Header>
      <Modal.Content scrolling>
          {
            currentDelivery ? notes.map(note => {
              return (
                <Segment>
                  <p>{note}</p>
                </Segment>
              )
            }) : null
          }
        <hr />
        <Form>
          <Form.Field>
            <label>New note</label>
            <TextArea value={note} onChange={e => setNote(e.target.value)} />
          </Form.Field>
          <Form.Field>
            <Button color="green" fluid={true} onClick={() => addNoteToDelivery()}>Add new Note</Button>
          </Form.Field>
        </Form>
      </Modal.Content>
    </Modal>
  )
}


function OrderRow({order}) {
  return [
    <Segment>
      <h3>#{order.order._id.substr(-6)}</h3>

      <h4>Items</h4>

      {
        order.items.map(item => {
          const v = item.variant
          return (
            <div>
              <h5>Product: {item.product.name}</h5>
              <small>Variant: {v.title}</small>

              <ul>
                {
                  item.options.map(option => {
                    return (<li>
                      {option.option.title}
                      <ul>
                        <li>{option.value.name} (+£{option.value.priceModifier})</li>
                      </ul>
                    </li>)
                  })
                }
              </ul>
            </div>
          )
        })
      }
    </Segment>
  ]
}
