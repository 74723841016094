import React from 'react';
import Tag from './tag';
import { Table } from 'semantic-ui-react'
import './style.scss';

const Tags = (props) => {
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Icon
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Actions
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                { props.tags.map((item, index) => {
                    return (
                        <Table.Row>
                            <Tag tag={item} key={`tag-${index}`}/>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    );
}

export default Tags;
