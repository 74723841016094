import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Button, Form, Grid, Header, Input, Segment } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'
import Select from '../components/fields/select'

export default function EditCoupon() {
  const match = useRouteMatch('/coupons/:id')
  const [createdCoupons, setCreatedCoupons] = useState([])
  const [coupon, setCoupon] = useState({
    discount_amount: {},
    target_rules: [],
    count: 1
  })
  useEffect(() => {
    const id = match.params.id
    if (id === 'new') return
    request(true).get(`/coupons/${id}`).then(e => setCoupon(e.data))
  }, [match.params.id])
  // const couponType = [
  //   {
  //     key: 'Vendor',
  //     value: 'Vendor',
  //     text: 'Vendor'
  //   },
  //   {
  //     key: 'Product',
  //     value: 'Product',
  //     text: 'Product'
  //   },
  //   {
  //     key: 'Variant',
  //     value: 'Variant',
  //     text: 'Variant'
  //   },
  // ]
  const couponType = [
    {
      name: 'Restricted',
      value: 'Restricted',
      label: 'Restricted'
    },
    {
      name: 'Platform',
      value: 'Platform',
      label: 'Platform'
    },
  ]
  const discountType = [
    {
      name: 'Fixed',
      value: 'Fixed',
      label: 'Fixed'
    },
    {
      name: 'Percent',
      value: 'Percent',
      label: 'Percent'
    },
  ]
  const targetRulesOptions = [
    {
      name: 'Vendor',
      value: 'Vendor',
      label: 'Vendor'
    },
    {
      name: 'Product',
      value: 'Product',
      label: 'Product'
    },
    {
      name: 'Variant',
      value: 'Variant',
      label: 'Variant'
    },
  ]
  const defaultTargetRule = {
    type: 'Vendor',
    values: []
  }

  const newTargetRule = () => {
    setCoupon({
      ...coupon,
      target_rules: [
        ...coupon.target_rules,
        defaultTargetRule
      ]
    })
  }

  const newTargetRuleValue = (index) => {
    const tar = coupon.target_rules
    const vals = tar[index].values
    vals.push('')
    tar[index] = {
      ...tar[index],
      values: vals
    }
    setCoupon({
      ...coupon,
      target_rules: tar
    })
  }

  const deleteRule = (index) => {
    const tar = coupon.target_rules
    tar.splice(index)
    setCoupon({
      ...coupon,
      target_rules: tar
    })
  }

  const deleteRuleValue = (ruleIndex, valueIndex) => {
    const tar = coupon.target_rules
    const values = coupon.target_rules[ruleIndex].values
    tar[ruleIndex].values = values.filter((_, i) => { return i !== valueIndex })
    setCoupon({
      ...coupon,
      target_rules: tar
    })
  }

  const updateRuleValue = (e, ruleIndex, valuesIndex) => {
    const rules = coupon.target_rules
    if (valuesIndex !== undefined) {
      rules[ruleIndex].values[valuesIndex] = e.target.value
    } else {
      rules[ruleIndex].type = e.target.value
    }
    setCoupon({
      ...coupon,
      target_rules: rules
    })
  }

  const updateFormValue = (e) => {
    setCoupon({
      ...coupon,
      [e.target.name]: e.target.value
    })
  }

  const createOrSave = () => {
    if (match.params.id === 'new') {
      let route = '/coupons';
      if (coupon.count > 1) {
        route = '/coupons/bulk';
      }
      request(true).post(route, coupon).then(e => {
        if (e.status === 200) {
          setCreatedCoupons(coupon.count > 1 ? e.data : [e.data])
          window.alert('Created! You will need to go back to the index to edit.')
          // history.push(`/coupons/${e.data._id}`)
        }
      }).catch(e => {
        window.alert(JSON.stringify(e.response.data))
      })
    } else {
      request(true).patch(`/coupons/${match.params.id}`, coupon).then(e => {
        if (e.status === 200) window.alert('Updated!')
      }).catch(e => {
        window.alert(JSON.stringify(e.response.data))
      })
    }
  }

  return (
    <Admin>
      <Header>Coupon</Header>
      {
        createdCoupons.length ? <>
          <h3>Created Codes</h3>
          {
            createdCoupons.map(a => {
              return (<p>{a.code}</p>)
            })
          }
        </> : null
      }
      <Form>
        <Form.Field>
          <label>Code (or bulk prefix)</label>
          <input onChange={e => updateFormValue(e)} name="code" defaultValue={coupon.code} type="text" />
        </Form.Field>
        <Form.Field>
          <label>Type</label>
          <Select onChange={e => updateFormValue(e)} name="type" value={coupon.type} options={couponType} />
        </Form.Field>
        {/* <Form.Field>
          <label>Shop Split</label>
        </Form.Field> */}
        {/*  */}
        <Form.Field>
          <label>Discount Type</label>
          <Select onChange={e => setCoupon({ ...coupon, discount_amount: { ...coupon.discount_amount, type: e.target.value } })} value={coupon.discount_amount.type} options={discountType} />
        </Form.Field>
        <Form.Field>
          <label>Discount Amount (p or decimal)</label>
          <Input onChange={e => setCoupon({ ...coupon, discount_amount: { ...coupon.discount_amount, amount: e.target.value } })} value={coupon.discount_amount.amount} type="number" />
        </Form.Field>
        {/*  */}
        <Form.Field>
          <label>Quantity Available</label>
          <input onChange={e => updateFormValue(e)} name="quantity_available" defaultValue={coupon.quantity_available} type="number" />
        </Form.Field>
        {/* <Form.Field>
          <label>Expiration Date</label>
          <Input onChange={e => updateFormValue(e)} name="expiration_date" defaultValue={coupon.expiration_date} type="datetime-local" />
        </Form.Field> */}
        {/*  */}
        <Form.Field>
          <label>Minumum Value (p)</label>
          <input onChange={e => updateFormValue(e)} name="minimum_value" defaultValue={coupon.minimum_value} type="number" />
        </Form.Field>
        <Form.Field>
          <label>Maximum Discount Amount</label>
          <input onChange={e => updateFormValue(e)} name="maximum_discount_amount" defaultValue={coupon.maximum_discount_amount} type="number" />
        </Form.Field>
        {
          match.params.id === 'new' ? (
            <Form.Field>
              <label><strong>Bulk Create Amount</strong></label>
              <input onChange={e => updateFormValue(e)} name="count" defaultValue={coupon.count} type="number" />
            </Form.Field>
          ) : null
        }
        {/*  */}
        <Form.Field>
          <label>Target Rules</label>
          <Button onClick={() => newTargetRule()}>New Rule</Button>
          {
            coupon.target_rules.map((rule, ruleIndex) => {
              return (
                <Segment key={`rule_${ruleIndex}`}>
                  <Button color="red" icon="delete" onClick={() => deleteRule(ruleIndex)}>Delete</Button>
                  <Form.Field>
                    <label>Type</label>
                    <Select onChange={e => updateRuleValue(e, ruleIndex)} value={rule.type} options={targetRulesOptions} />
                  </Form.Field>
                  <Form.Field>
                    <label>Values</label>
                    <Button icon="plus" onClick={() => newTargetRuleValue(ruleIndex)} />
                  </Form.Field>
                  <Grid>
                    {
                      rule.values.map((value, valIndex) => {
                        return (
                          <Form.Field key={`value_${ruleIndex}_${valIndex}`}>
                            <Input onChange={e => updateRuleValue(e, ruleIndex, valIndex)} defaultValue={value} type="text" />
                            <Button color="red" icon="delete" onClick={() => deleteRuleValue(ruleIndex, valIndex)} />
                          </Form.Field>
                        )
                      })
                    }
                  </Grid>
                </Segment>
              )
            })
          }
        </Form.Field>
      </Form>
      <Button onClick={() => createOrSave()}>
        Save
      </Button>
    </Admin>
  )
}
