import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Container, Grid, Header, Segment, Statistic } from 'semantic-ui-react'
import request from '../../helpers/request'

export default function DashboardStats() {
    const [statistics, setStatistics] = useState({})
    const [recentOrders, setRecentOrders] = useState([])
    const [upcomingDeliveries, setUpcomingDeliveries] = useState([])

    useEffect(() => {
        request(true).get('/admin/stats').then(a => setStatistics(a.data))
        request(true).get('/admin/recent-orders').then(a => setRecentOrders(a.data))
        request(true).get('/admin/deliveries').then(a => setUpcomingDeliveries(a.data))
    }, [])

    return (
        <Container>
            <Segment>
                <Grid columns={3}>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.totalCustomers || 0} label="Total Customers" />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.ordersToday || 0} label="Orders Today" />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.thisMonth || 0} label="Orders This Month" />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.lastMonth || 0} label="Orders Last Month" />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.activeSubscriptions || 0} label="Active Subscriptions" />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Statistic value={statistics.pausedSubscriptions || 0} label="Paused Subscriptions" />
                    </Grid.Column>
                </Grid>
            </Segment>
            <Grid columns={2}>
                <Grid.Column>
                    <Segment>
                        <Header>Recent Orders</Header>
                        {
                            recentOrders.map(_ => <RecentOrder {..._} />)
                        }
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        <Header>Upcoming Deliveries</Header>
                        {
                            upcomingDeliveries.map(_ => <UpcomingDelivery {..._} />)
                        }
                    </Segment>
                </Grid.Column>
            </Grid>
        </Container>
    )
}


const RecentOrder = ({ customer, price, items }) => {
    return (<div>
        <hr />
        <p style={{margin: 0}}><strong>{customer.name}</strong> <small style={{margin: 0}}>{customer.addresses[0].postcode}</small></p>
        <p style={{margin: 0}}>{items.length} total items</p>
        <p style={{margin: 0}}><strong>£{price.total}</strong></p>
    </div>)
}

const UpcomingDelivery = ({human, countdown, deliveries}) => {
    return (<div>
        <hr />
        <p style={{margin: 0}}>{human} - <small>{countdown}</small></p>
        <p style={{margin: 0}}><strong>{deliveries}</strong> deliveries</p>
    </div>)
}