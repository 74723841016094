// The cooler and more hip paginate

import React from 'react'
import { Pagination } from 'semantic-ui-react'

export default function Paginate2({ goToPage, data }) {
    const handleOnPageChange = (e, data) => {
        const {activePage} = data
        goToPage(activePage > -1 ? activePage : 1)
    }
    return (
        <Pagination secondary pointing defaultActivePage={data.page-1} totalPages={data.pageLimit} onPageChange={handleOnPageChange} ellipsisItem={null}  />
    )
}
