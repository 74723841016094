import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react'
import request from '../../../helpers/request';

const Product = (props) => {
    const delSubProd = (e) => {
        const c = window.confirm('Do you want to delete this variant?')
        if (c) {
            request(true).delete(`/products/${props.product._id}/${props.subproduct._id}`).then(e => {
                if (e.status === 200) {
                    alert('Deleted!')
                    props.update()
                }
            })
        }
    }
    const copy = (e) => {
        const c = window.confirm('Do you want to copy this variant?')
        if (c) {
            request(true).post(`/products/${props.product._id}/${props.subproduct._id}/copy`).then(e => {
                if (e.status === 200) {
                    alert('Coppied!')
                    props.update()
                }
            })
        }
    }
    return (
        <Segment>
            <div>
                <p>{ props.subproduct.title }</p>
            </div>
            <div>
                <Link to={`/vendors/${props.product.vendor}/products/${props.product._id}/${props.subproduct._id}`} className="action">
                    <Button color="orange">
                        Edit
                    </Button>
                </Link>
                <Button color="red" className="action" onClick={(e) => { e.preventDefault(); delSubProd(e) } }>
                    Delete
                </Button>
                <Button onClick={(e) => { e.preventDefault(); copy(e) } } icon="copy" color="teal"></Button>
            </div>
        </Segment>
    );
}

export default Product;
