import React from 'react';
import { Grid } from 'semantic-ui-react';
import Subproduct from './subproduct';

const Products = (props) => {
    return (
        <Grid columns="3">
            { props.product.products.map((item, index) => {
                return (
                    <Grid.Column>
                        <Subproduct update={props.update} copySubProduct={props.copySubProduct} deleteSubProduct={props.deleteSubProduct} product={props.product} subproduct={item} key={`subproduct-${index}`}/>
                    </Grid.Column>
                )
            })}
        </Grid>
    );
}

export default Products;
