import React, { useCallback, useEffect } from 'react';
import request from '../../helpers/request';
import { useHistory, Link } from 'react-router-dom';
import ChecksField from '../fields/checks';
import Select from '../fields/select';
import Radios from '../fields/radio';
import Subproducts from './subproducts/subproducts';
import {Button, Checkbox, Form, Grid, Header, Input, Segment} from 'semantic-ui-react'
import ImageManager from '../image-manager/image-manager';


const EditProduct = (props) => {
    const [formState, setFormState] = React.useState({
        name: '',
        tags: [],
        products: [],
        featured: false,
        live: false,
        weight: 0,
        quickDelivery: false
    });

    const [categories, setCategories] = React.useState([]);
    const [vendors, setVendors] = React.useState([]);
    const [tags, setTags] = React.useState([]);

    useEffect(() => {
        setFormState({
            ...formState,
            ...props.product
        });
        // eslint-disable-next-line
    }, [])

    const onChange = useCallback((event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }, [formState, setFormState]);

    const tagChange = useCallback((value) => {
        console.log(formState.tags, value);
        if (formState.tags.length !== value.length) {
            setFormState({
                ...formState,
                tags: value
            });
        }
    }, [setFormState, formState]);

    const liveChange = useCallback((name, value) => {
        console.log(name, formState[name])
        if (formState[name] !== value) {
            setFormState({
                ...formState,
                [name]: value === 'true'
            });
        }
    }, [setFormState, formState]);


    const history = useHistory();

    // const deleteSubProduct = (id) => {
    //     let st = JSON.parse(JSON.stringify(props.product.products));

    //     console.log('delete', st, id)
    //     st = JSON.parse(JSON.stringify(st.filter(a => a._id !== id)));
    //     console.log('deleted', JSON.stringify(st))

    //     setFormState({
    //         ...formState,
    //         products: st
    //     });

    //     console.log(formState)

    //     setTimeout(() => {
    //         console.log(formState)
    //         onSubmit();
    //     }, 1000);
    // }

    const onSubmit = useCallback((event) => {
        if (event) event.preventDefault();

        const postData = {
            name: formState.name,
            tags: formState.tags,
            vendor: formState.vendor,
            category: formState.category,
            products: formState.products,
            live: formState.live,
            quickDelivery: formState.quickDelivery || false
        }

        console.log(postData, formState);

        if (props.productId === null) {
            request(true).post('/products', {
                ...formState,
                images: undefined
            }).then(r => {
                history.push('/vendors/' + formState.vendor);
            }).catch(e => {
                console.log(e.response);
            });
        } else {
            request(true).patch(`/products/${props.productId}`, {
                ...formState,
                images: undefined
            }).then(r => {
                alert('Updated!')
                // history.push('/vendors/' + formState.vendor);
            }).catch(e => {
                console.log(e.response);
            })
        }
    }, [props.productId, formState, history]);

    const update = useCallback(() => {
        request(true).get('/categories?limit=100').then(r => {
            setCategories(r.data.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        }).catch(e => {
            console.log(e);
        })

        request(true).get('/vendors?limit=100').then(r => {
            setVendors(r.data.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        }).catch(e => {
            console.log(e);
        })

        request(true).get('/tags').then(r => {
            setTags(r.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        });

    }, [])

    useEffect(() => {
        update()
    }, [update]);

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-1 grid-gap-1">
                <Segment>
                    <Header>Basic</Header>
                    <Grid columns="3">
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Name</label>
                                    <Input name="name" type="text" placeholder="Name" value={ formState.name } onChange={ onChange }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Category</label>
                                    <Select name="category" options={categories} value={ formState.category } onChange={ onChange }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Vendor</label>
                                    <Select name="vendor" options={vendors} value={ formState.vendor } onChange={ onChange }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Weight</label>
                                    <Input name="weight" type="number" placeholder="Weight" value={ formState.weight } onChange={ onChange }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Rating</label>
                                    <Input name="rating" type="number" placeholder="Rating" value={ formState.rating } onChange={ onChange }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Is Featured?</label>
                                    <Radios name='featured' options={[{ value: true, label: 'True' }, { value: false, label: 'False' }]} onChange={ liveChange } value={ !!formState.featured }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Can be Quick Delivery?</label>
                                    <Checkbox checked={formState.quickDelivery} onChange={ () => setFormState({ ...formState, quickDelivery: !formState.quickDelivery }) } />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Live?</label>
                                    <Radios name='live' options={[{ value: true, label: 'True' }, { value: false, label: 'False' }]} onChange={ liveChange } value={ formState.live }/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment>
                    <Header>Tags</Header>
                    <ChecksField options={ tags } name="tags" value={ formState.tags } onChange={tagChange}/>
                </Segment>
                <Segment>
                    <Header>Variants</Header>
                    { props.productId ? <Link to={`/vendors/${props.vendorId}/products/${props.productId}/new`}><Button color="green">Add New Variant</Button></Link> : null }
                    { props.productId ?
                        <>
                            <Subproducts update={props.update} product={ props.product }/>
                        </>
                    : '' }
                </Segment>
                <Segment>
                    <h2>Images</h2>
                    <ImageManager endpoint={`/products/${props.productId}/images`} />
                </Segment>
                <Button>{ props.productId ? 'Update' : 'Create' }</Button>
            </div>
        </form>
    );
}

export default EditProduct;
