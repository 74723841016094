import React from 'react'
import ReactDOM from 'react-dom'
import Router from './router'
import './scss/main.scss'
// import 'semantic-ui-forest-themes/semantic.slate.min.css'
import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
)
