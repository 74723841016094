import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Checkbox, Form, Grid, Header, Input, Segment } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function EditDeliverySlots() {
  const params = useParams()
  const [slot, setSlot] = useState({})

  useEffect(() => {
    request(true).get(`/deliveryslots/${params.id}`).then(e => {
      setSlot(e.data)
    })
  }, [params.id])

  const update = () => {
    request(true).post(`/deliveryslots/${slot._id}`, slot).then(e => {
      if (e.status === 200) {
        return alert('Updated!')
      }
    })
  }

  const removeSlot = (index) => {
    let original = [].concat(slot.slots)

    original.splice(index, 1)

    setSlot({
      ...slot,
      slots: original
    })
  }

  const addSlot = () => {
    console.log(slot.slots)
    let original = [].concat(slot.slots)
    original.push({
      from: '00:00',
      to: '00:00',
      limit: 0
    })
    setSlot({
      ...slot,
      slots: original
    })
  }

  const slotOnChange = (index, e, name, val) => {
    let original = slot.slots

    original[index][name ? name : e.target.name] = val ? val : e.target.value

    setSlot({
      ...slot,
      slots: original
    })
  }

  return (
    <Admin>
      <Header>Edit Delivery Slot {slot.day}</Header>
      <Button onClick={() => update()} color="green">Update</Button>
      <Segment>
        <Form>
          <Form.Field>
            <label>Day</label>
            <Input type="text" defaultValue={slot.day} onChange={e => { setSlot({ ...slot, day: e.target.value }) }} />
          </Form.Field>
        </Form>
      </Segment>
      <Header>Slots</Header>
      <Button onClick={() => addSlot()} icon="plus" color="green"></Button>
      <Grid columns="3">
        {slot.slots ? slot.slots.map((s, i) => {
          return (
            <Grid.Column>
              <Segment color={s.quickDelivery ? 'orange' : undefined}>
                <Form>
                  <Form.Field>
                    <label>From</label>
                    <Input name="from" onChange={e => slotOnChange(i, e)} type="time" defaultValue={s.from} />
                  </Form.Field>
                  <Form.Field>
                    <label>To</label>
                    <Input name="to" onChange={e => slotOnChange(i, e)} type="time" defaultValue={s.to} />
                  </Form.Field>
                  <Form.Field>
                    <label>Limit</label>
                    <Input name="limit" onChange={e => slotOnChange(i, e)} type="number" defaultValue={s.limit} />
                  </Form.Field>
                  <Form.Field>
                    <label>Quick Delivery?</label>
                    <Checkbox name="quickDelivery" checked={s.quickDelivery}  onChange={e => slotOnChange(i, e, 'quickDelivery', !s.quickDelivery)} />
                  </Form.Field>
                </Form>
                <Button onClick={() => {
                  removeSlot(i)
                }}>Remove</Button>
              </Segment>
            </Grid.Column>
          )
        }) : null}
      </Grid>
    </Admin>
  )
}
