import React, { useCallback, useEffect } from 'react';
import request from '../../helpers/request';
import { useHistory } from 'react-router-dom';
import {Button, Form, Grid, Header, Input} from 'semantic-ui-react'
import ImageManager from '../image-manager/image-manager'
import Radios from '../fields/radio';


const EditCategory = (props) => {
    const [formState, setFormState] = React.useState({
        name: '',
    });

    const onChange = useCallback((event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }, [formState, setFormState]);

    const history = useHistory();

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        if (props.categoryId === null) {
            request(true).post('/categories', {
                ...formState,
                images: undefined,
                _id: undefined
            }).then(r => {
                history.push('/categories');
                window.alert('Updated')
            }).catch(e => {
                console.log(e.response);
                window.alert('Error', JSON.stringify(e.response))
            });
        } else {
            request(true).patch(`/categories/${props.categoryId}`, {
                ...formState,
                images: undefined,
                _id: undefined
            }).then(r => {
                history.push('/categories');
                window.alert('Updated')
            }).catch(e => {
                console.log(e.response);
                window.alert('Error', JSON.stringify(e.response))
            })
        }
    }, [props.categoryId, formState, history]);

    useEffect(() => {
        setFormState({
            ...props.category,
        });
    }, [setFormState, props.category]);

    const liveChange = useCallback((name, value) => {
        console.log(name, formState[name])
        if (formState[name] !== value) {
            setFormState({
                ...formState,
                [name]: value === 'true'
            });
        }
    }, [setFormState, formState]);

    return (
        <div className="grid grid-1 grid-gap-1">
            <label>Name</label>
            <Input name="name" type="text" placeholder="Name" value={ formState.name } onChange={ onChange }/>
            <Header>Images (Only the first will work)</Header>
            <ImageManager endpoint={`/categories/${props.categoryId}/images`} />
            <Grid columns="2">
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Featured</label>
                            <Radios name='featured' options={[{ value: true, label: 'True' }, { value: false, label: 'False' }]} onChange={ liveChange } value={ !!formState.featured }/>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Weight</label>
                            <Input name="weight" type="number" placeholder="Weight" value={ formState.weight } onChange={ onChange }/>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid>
            <Button onClick={onSubmit}>{ props.categoryId ? 'Update' : 'Create' }</Button>
        </div>
    );
}

export default EditCategory;
