import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter, matchPath } from 'react-router-dom';
import request from '../helpers/request';
import EditVendorComponent from '../components/vendors/editVendor';

class EditVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: {
                name: '',
                email: '',
                shortBio: '',
                longBio: '',
                highstreet: null,
                category: null,
            },
            vendorId: null,
            loadedVendorId: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const match = matchPath(props.history.location.pathname, {
            path: '/vendors/:id'
        });

        if (match.params.id === "new") {
            return {
                vendor: {
                    name: '',
                    email: '',
                    shortBio: '',
                    longBio: '',
                    highStreet: '',
                    category: '',
                },
                vendorId: null,
                loadedVendorId: null
            }
        } else {
            return {
                vendor: state.vendor,
                vendorId: match.params.id,
                loadedVendorId: state.loadedVendorId
            }
        }
    }

    componentDidMount() {
        if (this.state.vendorId) {
            this.loadVendor();
        }
    }

    componentDidUpdate() {
        if (this.state.vendorId !== this.state.loadedVendorId) {
            this.loadVendor();
        }
    }

    loadVendor() {
        request(true).get(`/vendors/${this.state.vendorId}`).then(r => {
            this.setState({
                vendor: r.data,
                loadedVendorId: this.state.vendorId
            });
        }).catch(r => {
            this.props.history.push('/vendors');
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Vendor</Title>
                <EditVendorComponent params={this.props.match.params} vendor={ this.state.vendor } vendorId={ this.state.vendorId }/>
            </Admin>
        );
    }
}

export default withRouter(EditVendor);
