import React from 'react';
import Category from './category';
import './style.scss';

const Categories = (props) => {
    return (
        <div className="categories">
            { props.categories.map((item, index) => {
                return (
                    <Category category={item} key={`category-${index}`}/>
                )
            })}
        </div>
    );
}

export default Categories;
