import Session from '../stores/session';
import { useHistory } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';

const Logout = view(() => {
    const history = useHistory();

    Session.reset();

    history.push('/');
})

export default Logout;
