import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Button, Form, Header } from 'semantic-ui-react'
import ImageManager from '../components/image-manager/image-manager'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function EditPromotions() {
  const [promotion, setPromotion] = useState({})
  const match = useRouteMatch('/promotions/:id')
  const history = useHistory()
  useEffect(() => {
    if (match.params.id === 'new') return

    request(true).get(`/promotions/${match.params.id}`).then(e => setPromotion(e.data))
  }, [match.params.id])
  const createOrSave = () => {
    if (match.params.id === 'new') {
      request(true).post('/promotions', promotion).then(e => {
        if (e.status === 200) {
          window.alert('Created!')
          history.push(`/promotions/${e.data._id}`)
        }
      })
    } else {
      request(true).patch(`/promotions/${match.params.id}`, promotion).then(e => {
        if (e.status === 200) window.alert('Updated!')
      })
    }
  }
  const updateValue = (e) => {
    setPromotion({
      ...promotion,
      [e.target.name]: e.target.value
    })
  }
  return (
    <Admin>
      <Header>Promotion</Header>
      <Form onSubmit={e => {e.preventDefault(); createOrSave()}}>
        <Form.Field>
          <label>Title</label>
          <Form.Input onChange={updateValue} name="title" defaultValue={promotion.title} type="text" placeholder="Title" required />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <Form.TextArea onChange={updateValue} name="description" defaultValue={promotion.description} type="text" placeholder="Description" required />
        </Form.Field>
        <Form.Field>
          <label>Web Position Key</label>
          <Form.Input onChange={updateValue} name="web_position_key" defaultValue={promotion.web_position_key} type="text" placeholder="Web Position Key" required />
        </Form.Field>
        {
          match.params.id !== 'new' ? <Form.Field>
            <label>Image</label>
            <ImageManager endpoint={`/promotions/${match.params.id}/image`} />
          </Form.Field> : null
        }
        
        <Button color="green">Save</Button>
      </Form>
    </Admin>
  )
}
