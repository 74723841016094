import React from 'react';
import Highstreet from './highstreet';
import './style.scss';

const Highstreets = (props) => {
    return (
        <div className="highstreets">
            { props.highstreets.map((item, index) => {
                return (
                    <Highstreet highstreet={item} key={`highstreet-${index}`}/>
                )
            })}
        </div>
    );
}

export default Highstreets;
