import axios from 'axios';

export default (authed = false) => {
    let obj = {
        baseURL: process.env.REACT_APP_API_URL,
    };

    if (authed) {
        obj.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
    }

    const instance = axios.create(obj)

    instance.interceptors.response.use((request) => {
        const newToken = request.headers['x-jwt-update']
        if (newToken) {
            localStorage.setItem('user-token', newToken)
        }
        return request;
    })

    return instance;
}

export const errors = {
    'INVALID_CREDENTIALS': 'Incorrect Credentials'
}

export const getErrorText = (code) => {
    if (errors.hasOwnProperty(code)) {
        return errors[code];
    } else {
        return code;
    }
}

export const messages = {
    'PASSWORD_UPDATED': 'Your password has been updated'
}

export const getMessageText = (code) => {
    if (messages.hasOwnProperty(code)) {
        return messages[code];
    } else {
        return code;
    }
}
