import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter, matchPath } from 'react-router-dom';
import EditTagComponent from '../components/tags/editTag';
import request from '../helpers/request';

class EditTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: null,
            tagId: null,
            loadedTagId: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const match = matchPath(props.history.location.pathname, {
            path: '/tags/:id'
        });

        if (match.params.id === "new") {
            return {
                tag: {
                    name: '',
                },
                tagId: null,
                loadedTagId: null
            }
        } else {
            return {
                tag: state.tag,
                tagId: match.params.id,
                loadedTagId: state.loadedTagId
            }
        }
    }

    componentDidMount() {
        if (this.state.tagId) {
            this.loadTag();
        }
    }

    componentDidUpdate() {
        if (this.state.tagId !== this.state.loadedTagId) {
            this.loadTag();
        }
    }

    loadTag() {
        request(true).get(`/tags/${this.state.tagId}`).then(r => {
            this.setState({
                tag: r.data,
                loadedTagId: this.state.tagId
            })
        }).catch(r => {
            this.props.history.push('/tags');
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Tag</Title>
                { this.state.tag ?
                    <EditTagComponent tag={ this.state.tag } tagId={ this.state.tagId }/>
                : '' }
            </Admin>
        );
    }
}

export default withRouter(EditTag);
