import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Home from './pages/home';
import { view } from '@risingstack/react-easy-state';
import session from './stores/session';
import Login from './pages/login';
import Highstreets from './pages/highstreets';
import Highstreet from './pages/editHighstreet';
import Vendors from './pages/vendors';
import EditVendor from './pages/editVendor';
import Categories from './pages/categories';
import Category from './pages/editCategory';
import Tags from './pages/tags';
import Tag from './pages/editTag.jsx';
import Logout from './pages/logout';
import Products from './pages/products';
import Product from './pages/editProduct';
import EditSubproduct from './pages/editSubproduct';
import Variables from './pages/variables';
import DeliverySlots from './pages/deliverySlots';
import EditDeliverySlots from './pages/editDeliverySlots';
import LogisticsPage from './pages/logistics';
import Export from './pages/export';
import Bids from './pages/bids';
import BidVendors from './pages/bidVendors';
import EditBid from './pages/editBid';
import EditBidVendors from './pages/editBidVendors';
import Coupons from './pages/coupons';
import EditCoupon from './pages/editCoupon';
import Promotions from './pages/promotions';
import EditPromotions from './pages/editPromotions';
import Customers from './pages/customers';
import DangerZone from './pages/dangerZone';

export default class Router extends Component {
    render () {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path='/login' component={ Login }/>
                    <Route path='/logout' component={ Logout }/>
                    <ProtectedRoute path='/promotions/:id' component={ EditPromotions } />
                    <ProtectedRoute path='/promotions' component={ Promotions } />

                    <ProtectedRoute path='/coupons/:id' component={ EditCoupon } />
                    <ProtectedRoute path='/coupons' component={ Coupons } />

                    <ProtectedRoute path='/customers' component={ Customers } />

                    <ProtectedRoute path='/vendors/:vendorId/products/:id/:variant' component={ EditSubproduct }/>
                    <ProtectedRoute path='/vendors/:vendorId/products/:id' component={ Product }/>
                    <ProtectedRoute path='/vendors/:vendorId/products' component={ Products }/>
                    <ProtectedRoute path='/vendors/:id' component={ EditVendor }/>
                    <ProtectedRoute path='/vendors' component={ Vendors }/>

                    <ProtectedRoute path='/highstreets/:id' component={ Highstreet }/>
                    <ProtectedRoute path='/highstreets' component={ Highstreets }/>

                    <ProtectedRoute path='/categories/:id' component={ Category }/>
                    <ProtectedRoute path='/categories' component={ Categories }/>

                    <ProtectedRoute path='/tags/:id' component={ Tag }/>
                    <ProtectedRoute path='/tags' component={ Tags }/>

                    <ProtectedRoute path='/bids/:id' component={EditBid} />
                    <ProtectedRoute path='/bids' component={Bids} />

                    <ProtectedRoute path='/bidvendors/:id' component={EditBidVendors} />
                    <ProtectedRoute path='/bidvendors' component={BidVendors} />

                    <ProtectedRoute path='/variables' component={ Variables }/>

                    <ProtectedRoute path='/deliveryslots/:id' component={ EditDeliverySlots } />
                    <ProtectedRoute path='/deliveryslots' component={ DeliverySlots } />

                    <ProtectedRoute path='/logistics' component={LogisticsPage} />
                    <ProtectedRoute path='/export' component={ Export } />

                    <ProtectedRoute path='/danger' component={ DangerZone } />

                    <ProtectedRoute path='/' component={ Home }/>
                </Switch>
            </BrowserRouter>
        )
    }
}

const ProtectedRoute = withRouter(view(class ProtectedRoute extends Component {
    constructor(props) {
        super(props);

        const data = Object.assign({}, props);
        delete data.component;

        this.state = {
            authenticated: null,
            props: data
        }
    }

    componentDidMount() {
        const alive = session.isAlive();

        if (alive === true) {
            this.updateAuthState(session.user.admin);
        } else if (alive === false) {
            this.updateAuthState(false);
        } else {
            alive.then(r => {
                this.updateAuthState(session.user.name && session.user.admin);
            })
        }
    }

    updateAuthState = (auth) => {
        this.setState({
            authenticated: auth
        }, () => {
            if (this.state.authenticated === false) {
                session.reset();
                this.props.history.push('/login');
            }
        });
    }

    render() {
        if (this.state.authenticated === true) {
            return (
                <Route {...this.state.props} {...this.props} />
            );
        } else if (this.state.authenticated === false) {
            return <Redirect to="/login" />
        }

        return null
    }
}));
