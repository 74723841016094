import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import request from '../../helpers/request';

const Vendor = (props) => {

    const history = useHistory();

    const deleteVendor = () => {
        const confirm = window.confirm('Delete vendor?')
        if (confirm) {
            request(true).delete(`/vendors/${props.vendor._id}`).then(r => {
                history.push('/vendors');
            }).catch(e => {
                console.log(e.response);
            })
        }
    }

    const copy = (e) => {
        e.preventDefault();
        let conf = window.confirm(`Do you want to copy "${props.vendor.name}"?`)

        if (conf) {
            request(true).post(`/vendors/${props.vendor._id}/copy`).then(r => {
                alert(r.statusText)
                props.update()
            }).catch(e => {
                alert(e.response.data)
                props.update()
            })
        }
    }

    return (
        <div className="vendor">
            <div className="name">
                { props.vendor.name }
            </div>
            <div className="actions grid grid-2 grid-gap-1">
                <Link to={`/vendors/${props.vendor._id}`}>
                    <Button>Edit</Button>
                </Link>
                <Button color="red" onClick={deleteVendor}>
                    Delete
                </Button>
                <Button icon="copy" color="teal" onClick={copy} />
            </div>
        </div>
    );
}

export default Vendor;
