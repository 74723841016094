import React, { useState } from 'react';
// import Input from '../fields/input';
import request from '../../helpers/request';
import session from '../../stores/session';
import { useHistory } from 'react-router-dom';
import { Input, Form, Button, Grid, Segment, Header,  } from 'semantic-ui-react'

const Login = () => {

    const [formState, setFormState] = useState({ email: '', password: '' });
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory();

    const submitLogin = (event) => {
        event.preventDefault();
        setLoading(true)
        request().post('/auth/login', formState).then(r => {
            session.setToken(r.data.token);
            session.fetch();
            history.push('/');
            setLoading(false)
        }).catch(e => {
            setLoading(false)
            setError(e.response.data.error || e.response.data)
        })
    }

    const formChange = (event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Grid textAlign="center" verticalAlign="middle" style={{height: '100%'}}>
            <Grid.Column style={{maxWidth:320}}>
                <Segment>
                    <Header as="h2" content="Login" />
                    <Form onSubmit={ submitLogin }>
                        {
                            error ? <Header as="h4" color="red" content={error} /> : null
                        }
                        <Form.Field>
                            <label>Email</label>
                            <Input name="email" type="email" placeholder="Email" onChange={ formChange } required/>
                        </Form.Field>
                        <Form.Field>
                            <label>Password</label>
                            <Input name="password" type="password" placeholder="Password" onChange={ formChange } required/>
                        </Form.Field>
                        <Button loading={loading}>Submit</Button>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default Login;
