import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import FileDownload from 'js-file-download'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'
import { useState } from 'react'

export default function Export() {
  const [after, setAfter] = useState('')
  const downloadTo = (name, type) => {
    request(true).get(`/dashboard/export/${name}.${type}${after ? '?after=' + after : ''}`, {
      responseType: 'blob'
    }).then(e => {
      FileDownload(e.data, `${name}.${type}`)
    })
  }
  return (
    <Admin>
      <label>Deliveries After</label>
      <Input type="datetime-local" onChange={e => setAfter(e.target.value)} />
      <br />
      <label>Orders</label>
      <br />
      <Button.Group>
        <Button onClick={() => downloadTo('orders', 'csv')}>Export all orders to CSV</Button>
        <Button onClick={() => downloadTo('orders', 'json')}>Export all orders to JSON</Button>
      </Button.Group>
      {/* <Button onClick={() => downloadTo('orders', 'csv')}>Export all orders to CSV</Button> */}
    </Admin>
  )
}
