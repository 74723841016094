import React from 'react';
import { Header } from 'semantic-ui-react';
import './style.scss';

const Title = (props) => {
    return (
        <Header as='h2'>
            {props.children}
        </Header>
    );
}

export default Title;
