import React, { useCallback, useEffect } from 'react';

const ChecksField = ({ onChange: propsOnChange, ...props}) => {
    const [checks, setChecks] = React.useState([]);
    const [oldChecksLength, setOldChecksLength] = React.useState(-1);

    const isChecked = useCallback((item) => {
        if (props.value) {
            return props.value.indexOf(item.value) !== -1;
        }

        return false;
    }, [props.value]);

    const onChange = useCallback((value, state) => {
        if (state) {
            if (checks.indexOf(value) === -1) {
                setChecks([].concat(checks, [value]));
            }
        } else {
            let index = checks.indexOf(value);

            if (index !== -1) {
                setChecks(checks.splice(index, 1))
            }
        }
    }, [setChecks, checks]);

    useEffect(() => {
        if (props.value) {
            if (props.value.length !== checks.length) {
                if (checks.length !== oldChecksLength && oldChecksLength !== -1) {
                    propsOnChange(checks);
                    setOldChecksLength(checks.length);
                } else {
                    setChecks(props.value);
                    setOldChecksLength(props.value.length);
                }
            }
        }
    }, [propsOnChange, checks, props.value, oldChecksLength])

    return (
        <div className="checks-wrapper">
            { props.options.map((item, index) => {
                return (
                    <Check key={`${props.name}-${index}`} label={ item.label } value={ item.value } checked={isChecked(item)} onChange={onChange}/>
                )
            })}
        </div>
    );
}

const Check = ({ value, checked, onChange: propsOnChange, ...props }) => {
    const [check, setCheck] = React.useState(checked);

    const onChange = useCallback((event) => {
        if (check !== event.target.check) {
            setCheck(event.target.checked);
        }
    }, [setCheck, check]);

    useEffect(() => {
        if (checked !== check) {
            propsOnChange(value, check);
        }
    }, [value, propsOnChange, check, checked]);

    return (
        <div className="check-wrapper">
            <input type="checkbox" name={ props.name } value={ value } checked={ check } onChange={onChange}/>
            &nbsp;
            <label>{ props.label }</label>
        </div>
    )
}

export default ChecksField;
