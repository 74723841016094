import React, { useCallback, useEffect } from 'react';
import {Grid, Input, Label, TextArea, Button} from 'semantic-ui-react';
import request from '../../helpers/request';
import { useHistory, Link } from 'react-router-dom';
import Select from '../fields/select';
import Checks from '../fields/checks';
import ImageManager from '../image-manager/image-manager';

const EditVendor = (props) => {
    const [formState, setFormState] = React.useState({
        name: '',
        email: '',
        shortBio: '',
        longBio: '',
        highstreet: '',
        category: '',
        tags: []
    });

    const [categories, setCategories] = React.useState([]);
    const [highstreets, setHighstreets] = React.useState([]);
    const [tags, setTags] = React.useState([]);

    const onChange = useCallback((event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }, [formState, setFormState]);

    const tagChange = useCallback((value) => {
        console.log(formState.tags, value);
        if (formState.tags.length !== value.length) {
            setFormState({
                ...formState,
                tags: value
            });
        }
    }, [setFormState, formState]);

    const history = useHistory();

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        const postData = {
            name: formState.name,
            email: formState.email,
            phone: formState.phone,
            website: formState.website,
            shortBio: formState.shortBio,
            longBio: formState.longBio,
            highStreet: formState.highstreet,
            category: formState.category ? formState.category._id || formState.category : undefined,
            tags: formState.tags,
            storeGeo: {
                lat: formState.geolat,
                long: formState.geolng
            },
            address: {
                to: formState.addressTo,
                company: formState.addressCompany,
                buildingName: formState.addressBuilding,
                street: formState.addressStreet,
                locality: formState.addressLocality,
                town: formState.addressTown,
                postcode: formState.addressPostcode,
                note: formState.addressNote
            },
            stripeApplicationFee: formState.stripeApplicationFee || undefined,
            images: formState.images
        }

        if (props.vendorId === null) {
            request(true).post('/vendors', postData).then(r => {
                history.push('/vendors');
            }).catch(e => {
                alert(e.response.data)
                console.log(e.response);
            })
        } else {
            request(true).patch(`/vendors/${props.vendorId}`, postData).then(r => {
                history.push('/vendors');
            }).catch(e => {
                alert(e.response.data)
                console.log(e.response);
            })
        }
    }, [props.vendorId, formState, history]);

    useEffect(() => {
        setFormState({
            name: props.vendor.name,
            email: props.vendor.email,
            shortBio: props.vendor.shortBio,
            longBio: props.vendor.longBio,
            highstreet: props.vendor.highStreet,
            category: props.vendor.category,
            website: props.vendor.website,
            phone: props.vendor.phone,
            geolat: (props.vendor.storeGeo ?? {}).lat,
            geolng: (props.vendor.storeGeo ?? {}).long,
            tags: props.vendor.tags ?? [],
            addressCompany: (props.vendor.address ?? {}).company,
            addressBuilding: (props.vendor.address ?? {}).buildingName,
            addressStreet: (props.vendor.address ?? {}).street,
            addressLocality: (props.vendor.address ?? {}).locality,
            addressTown: (props.vendor.address ?? {}).town,
            addressPostcode: (props.vendor.address ?? {}).postcode,
            stripeAccountId: props.vendor.stripeAccountId,
            stripeApplicationFee: props.vendor.stripeApplicationFee || 0.05
        });

        request(true).get('/categories?limit=100').then(r => {
            setCategories(r.data.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        }).catch(e => {
            console.log(e);
        })

        request(true).get('/highstreets?limit=100').then(r => {
            setHighstreets(r.data.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        }).catch(e => {
            console.log(e);
        })

        request(true).get('/tags').then(r => {
            setTags(r.data.map(item => {
                return {
                    value: item._id,
                    label: item.name
                }
            }));
        });
    }, [setFormState, props.vendor]);

    const sendStripeConnectEmail = () => {
        request(true).post('/vendors/connect/stripe', {
            vendor: props.vendorId
        }).then(e => {
            if (e.status === 200) {
                alert('Email has been sent')
            } else {
                alert('Email has not been sent')
            }
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-1 grid-gap-1">
                <Link to={`/vendors/${props.params.id}/products/`}><Button>View/Edit Products</Button></Link>
                <h2>Basic</h2>
                <Grid columns={4}>

                    <Grid.Column>
                        <label>Name *</label>
                        <Input name="name" type="text" placeholder="Name" value={ formState.name } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Email Address *</label>
                        <Input name="email" type="text" placeholder="Email" value={ formState.email } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Website *</label>
                        <Input name="website" type="text" placeholder="Website" value={ formState.website } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Phone *</label>
                        <Input name="phone" type="text" placeholder="Phone" value={ formState.phone } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Longitude</label>
                        <Input name="geolng" type="text" placeholder="Longitude" value={ formState.geolng } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Latitude</label>
                        <Input name="geolat" type="text" placeholder="Latitude" value={ formState.geolat } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Short Bio *</label>
                        <TextArea name="shortBio" type="text" placeholder="Short Bio" value={ formState.shortBio} onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Long Bio *</label>
                        <TextArea name="longBio" type="text" placeholder="Long Bio" value={ formState.longBio} onChange={ onChange }></TextArea>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Category *</label>
                        <Select name="category" options={categories} value={ formState.category ? formState.category._id || formState.category : undefined } onChange={ onChange }/>
                    </Grid.Column>

                    <Grid.Column>
                        <label>Highstreet *</label>
                        <Select name="highstreet" options={highstreets} value={ formState.highstreet } onChange={ onChange }/>
                    </Grid.Column>
                </Grid>
                <div>
                    <h2>Images</h2>
                    <ImageManager endpoint={`/vendors/${props.vendorId}/images`} />
                </div>
                <div>
                    <h2>Stripe</h2>
                    {
                        props.vendorId ? !props.vendor.stripeAccountId ? <div>
                            <Label color="red" content="Stripe Not Configured" />
                            <Button color="blue" onClick={(e) => {e.preventDefault();sendStripeConnectEmail();}}>Send Connect Email</Button>
                        </div> : <div>
                            <Label color="green" content="Stripe Configured" />
                            <p>Account Id <strong>{props.vendor.stripeAccountId}</strong></p>
                            <label>Application Fee (0.05 = 5%)</label>
                            <TextArea name="stripeApplicationFee" type="text" placeholder="Stripe Application Fee" value={ formState.stripeApplicationFee } onChange={ onChange }/>
                        </div> : <div>
                            <p>Stripe can be connected after Vendor is created.</p>
                        </div>
                    }
                </div>

                <h2>Address</h2>
                <Grid columns={2}>
                    <Grid.Column>
                        <label>Company *</label>
                        <Input name="addressCompany" type="text" placeholder="Address - Company" value={ formState.addressCompany } onChange={ onChange }/>
                    </Grid.Column>
                    <Grid.Column>
                        <label>Building *</label>
                        <Input name="addressBuilding" type="text" placeholder="Address - Building" value={ formState.addressBuilding } onChange={ onChange }/>
                    </Grid.Column>
                    <Grid.Column>
                        <label>Street *</label>
                        <Input name="addressStreet" type="text" placeholder="Address - Street" value={ formState.addressStreet } onChange={ onChange }/>
                    </Grid.Column>
                    <Grid.Column>
                        <label>Locality *</label>
                        <Input name="addressLocality" type="text" placeholder="Address - Locality" value={ formState.addressLocality } onChange={ onChange }/>
                    </Grid.Column>
                    <Grid.Column>
                        <label>Town *</label>
                        <Input name="addressTown" type="text" placeholder="Address - Town" value={ formState.addressTown } onChange={ onChange }/>
                    </Grid.Column>
                    <Grid.Column>
                        <label>Postcode *</label>
                        <Input name="addressPostcode" type="text" placeholder="Address - Postcode" value={ formState.addressPostcode } onChange={ onChange }/>
                    </Grid.Column>
                </Grid>
                <h2>Tags</h2>
                <div className="grid grid-1 grid-gap-1">
                    <Checks options={ tags } name="tags" value={ formState.tags.map(a => a._id || a) } onChange={tagChange}/>
                </div>
                <Button>{ props.vendorId ? 'Update' : 'Create' }</Button>
            </div>
        </form>
    );
}

export default EditVendor;
