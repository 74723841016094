import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter, matchPath } from 'react-router-dom';
import request from '../helpers/request';
import EditProductComponent from '../components/products/editProduct';

class EditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            productId: null,
            loadedProductId: null,
        }

        this.loadProduct = this.loadProduct.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        const match = matchPath(props.history.location.pathname, {
            path: `/vendors/:vendorId/products/:id`
        });

        if (match.params.id === "new") {
            return {
                product: {
                    name: '',
                    tags: [],
                    vendor: '',
                    category: '',
                    products: []
                },
                productId: null,
                loadedProductId: null
            }
        } else {
            return {
                product: state.product,
                productId: match.params.id,
                loadedProductId: state.loadedProductId
            }
        }
    }

    componentDidMount() {
        if (this.state.productId) {
            this.loadProduct();
        }
    }

    componentDidUpdate() {
        if (this.state.productId !== this.state.loadedProductId) {
            this.loadProduct();
        }
    }

    loadProduct() {
        console.log(this.state)
        request(true).get(`/products/${this.state.productId}`).then(r => {
            this.setState({
                product: r.data,
                loadedProductId: this.state.productId
            }, () => {
                console.log(this.state);
            })
        }).catch(r => {
            this.props.history.push('/products');
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Product</Title>
                { this.state.product ?
                    <EditProductComponent update={this.loadProduct} vendorId={this.props.match.params.vendorId} productId={ this.state.loadedProductId } product={this.state.product}/>
                : '' }
            </Admin>
        );
    }
}

export default withRouter(EditProduct);
