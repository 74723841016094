import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'semantic-ui-react'
import Title from '../components/title/title'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function Bids() {
  const [bids, setBids] = useState([])

  const getData = () => request(true).get('/bids?limit=500').then(e => setBids(e.data.data))

  useEffect(() => {
    getData()
  }, []) 

  const deleteBid = (id) => {
    const conf = window.confirm('Delete?')
    if (conf) {
      request(true).delete(`/bids/${id}`).then(e => {
        getData()
        if (e.status === 200) return window.alert('Deleted')
        window.alert(e.statusText)
      })
    }
  }

  return (
    <Admin>
      <Title>Bids</Title>
      <Link to="/bids/new"><Button color="green" size="tiny"><span className="fas fa-plus"></span></Button></Link>
      <Table>
        <Table.Header>
          <Table.Row>
            <td>Name</td>
            <td>Postcode</td>
            <td>Actions</td>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            bids.map(bid => {
              return (
                <Table.Row>
                  <td>{bid.name}</td>
                  <td>{bid.address.postcode}</td>
                  <td>
                    <Link to={`/bids/${bid._id}`}><Button color="green">Update</Button></Link>
                    <Button onClick={() => deleteBid(bid._id)} color="red">Delete</Button>
                  </td>
                </Table.Row>
              )
            })
          }
          
        </Table.Body>
      </Table>
    </Admin>
  )
}
