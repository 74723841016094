import React from 'react';
import Vendor from './vendor';
import './style.scss';

const Vendors = (props) => {
    return (
        <div className="vendors">
            { props.vendors.map((item, index) => {
                return (
                    <Vendor update={props.update} vendor={item} key={`vendor-${index}`}/>
                )
            })}
        </div>
    );
}

export default Vendors;
