import React, { Component } from 'react';
import Admin from '../layouts/admin/Admin';
import Title from '../components/title/title';
import { withRouter, matchPath } from 'react-router-dom';
import request from '../helpers/request';
import EditCategoryComponent from '../components/categories/editCategory';

class EditCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {
                name: ''
            },
            categoryId: null,
            loadedCategoryId: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const match = matchPath(props.history.location.pathname, {
            path: '/categories/:id'
        });

        if (match.params.id === "new") {
            return {
                category: {
                    name: ''
                },
                categoryId: null,
                loadedCategoryId: null
            }
        } else {
            return {
                category: state.category,
                categoryId: match.params.id,
                loadedCategoryId: state.loadedCategoryId
            }
        }
    }

    componentDidMount() {
        if (this.state.categoryId) {
            this.loadCategory();
        }
    }

    componentDidUpdate() {
        if (this.state.categoryId !== this.state.loadedCategoryId) {
            this.loadCategory();
        }
    }

    loadCategory() {
        request(true).get(`/categories/${this.state.categoryId}`).then(r => {
            this.setState({
                category: r.data,
                loadedCategoryId: this.state.categoryId
            });
        }).catch(r => {
            console.log(r);
            this.props.history.push('/categories');
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    render() {
        return (
            <Admin>
                <Title>Edit Category</Title>
                <EditCategoryComponent category={this.state.category} categoryId={this.state.categoryId}/>
            </Admin>
        );
    }
}

export default withRouter(EditCategory);
