import React, { useEffect } from 'react'
import { useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Form } from 'semantic-ui-react'
import ImageManager from '../components/image-manager/image-manager'
import Title from '../components/title/title'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function EditBidVendors() {
  const match = useRouteMatch('/bidVendors/:id')
  const type = match.params.id === 'new' ? 'New' : 'Update'

  const [currentBV, setCurrentBV] = useState({})

  const updateData = () => {
    const method = type === 'New' ? 'post' : 'patch'
    request(true)[method](`/bidvendors/${type === 'Update' ? match.params.id : ''}`, currentBV).then(e => {
      window.alert(e.statusText)
    })
  }

  useEffect(() => {
    if (type === 'Update') {
      request(true).get(`/bidvendors/${match.params.id}`).then(e => setCurrentBV(e.data))
    }
  }, [match.params.id, type])

  return (
    <Admin>
      <Title>{type} Bid Vendor</Title>
      <div>
        <Form onSubmit={() => updateData()}>
          <h2>Details</h2>
          <Form.Field>
            <label>Name</label>
            <input type="text" placeholder="Name" defaultValue={currentBV.name} onChange={e => setCurrentBV({ ...currentBV, name: e.target.value })} />
          </Form.Field>
          <Form.Field>
            <label>Locality</label>
            <input type="text" placeholder="Locality" defaultValue={currentBV.locality} onChange={e => setCurrentBV({ ...currentBV, locality: e.target.value })} />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <input type="text" placeholder="Description" defaultValue={currentBV.description} onChange={e => setCurrentBV({ ...currentBV, description: e.target.value })} />
          </Form.Field>
          <Form.Field>
            <label>Website</label>
            <input type="text" placeholder="Website" defaultValue={currentBV.website} onChange={e => setCurrentBV({ ...currentBV, website: e.target.value })} />
          </Form.Field>
          {
            type === 'Update' ? <ImageManager endpoint={`/bidvendors/${match.params.id}/images`} /> : null
          }

          <h2>Social</h2>
          <Form.Field>
            <label>Link</label>
            <input type="text" placeholder="Link" defaultValue={currentBV.social ? currentBV.social.link : null} onChange={e => setCurrentBV({ ...currentBV, social: { ...currentBV.social, link: e.target.value } })} />
          </Form.Field>
          <Form.Field>
            <label>Icon</label>
            <input type="text" placeholder="Icon" defaultValue={currentBV.social ? currentBV.social.icon : null} onChange={e => setCurrentBV({ ...currentBV, social: { ...currentBV.social, icon: e.target.value } })} />
          </Form.Field>

          <h2>Address</h2>
          <Form.Field>
            <label>Line 1</label>
            <input type="text" placeholder="Line 1" defaultValue={currentBV.address ? currentBV.address.line1 : null} onChange={e => { setCurrentBV({ ...currentBV, address: { ...currentBV.address, line1: e.target.value } }) }} />
          </Form.Field>
          <Form.Field>
            <label>Town</label>
            <input type="text" placeholder="Town" defaultValue={currentBV.address ? currentBV.address.town : null} onChange={e => { setCurrentBV({ ...currentBV, address: { ...currentBV.address, town: e.target.value } }) }} />
          </Form.Field>
          <Form.Field>
            <label>Postcode</label>
            <input type="text" placeholder="Postcode" defaultValue={currentBV.address ? currentBV.address.postcode : null} onChange={e => { setCurrentBV({ ...currentBV, address: { ...currentBV.address, postcode: e.target.value } }) }} />
          </Form.Field>

          {/* <h2>Bid</h2>
          <Form.Field>
            <label>Bid</label>
          </Form.Field> */}

          <h2>Geo</h2>
          <Form.Field>
            <label>Latitude</label>
            <input type="number" step="any" placeholder="Latitude" defaultValue={currentBV.geo ? currentBV.geo.coordinates[0] : null} onChange={e => setCurrentBV({ ...currentBV, geo: { ...(currentBV.geo || {type:'Point'}), coordinates: [Number(e.target.value), (currentBV.geo || {type:'Point',coordinates:[]}).coordinates[1]] } })} />
          </Form.Field>
          <Form.Field>
            <label>Longitude</label>
            <input type="number" step="any" placeholder="Longitude" defaultValue={currentBV.geo  ? currentBV.geo.coordinates[1] : null} onChange={e => setCurrentBV({ ...currentBV, geo: { ...(currentBV.geo || {type:'Point'}), coordinates: [(currentBV.geo || {type:'Point',coordinates:[]}).coordinates[0], Number(e.target.value)] } })} />
          </Form.Field>

          <Form.Button color="green">Save</Form.Button>
          
        </Form>
      </div>
    </Admin>
  )
}
