import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'semantic-ui-react'
import Title from '../components/title/title'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function BidVendors() {
  const [bidVendors, setBidVendors] = useState([])

  const getData = () => request(true).get('/bidvendors?limit=500').then(e => setBidVendors(e.data.data))

  useEffect(() => {
    getData()
  }, [])

  const deleteBV = (id) => {
    const conf = window.confirm('Delete?')
    if (conf) {
      request(true).delete(`/bidvendors/${id}`).then(e => {
        window.alert(e.statusText)
        getData()
      })
    }
  }

  return (
    <Admin>
      <Title>Bid Vendors</Title>
      <Link to="/bidvendors/new"><Button color="green" size="tiny"><span className="fas fa-plus"></span></Button></Link>
      <Table>
        <Table.Header>
          <Table.Row>
            <td>Name</td>
            <td>Locality</td>
            <td>Actions</td>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            bidVendors.map(bv => {
              return (
                <Table.Row>
                  <td>{bv.name}</td>
                  <td>{bv.locality}</td>
                  <td>
                    <Link to={`/bidvendors/${bv._id}`}><Button color="green">Update</Button></Link>
                    <Button onClick={() => deleteBV(bv._id)} color="red">Delete</Button>
                  </td>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </Admin>
  )
}
