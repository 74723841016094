import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import request from '../../helpers/request';

const Category = (props) => {

    const history = useHistory();

    const deleteCategory = () => {
        request(true).delete(`/categories/${props.category._id}`).then(r => {
            history.push('/categories');
        }).catch(e => {
            console.log(e.response);
        })
    }

    return (
        <div className="category">
            <div className="name">
                { props.category.name }
            </div>
            <div className="actions grid grid-2 grid-gap-1">
                <Link to={`/categories/${props.category._id}`} className="action">
                    Edit
                </Link>
                <div className="action" onClick={deleteCategory}>
                    Delete
                </div>
            </div>
        </div>
    );
}

export default Category;
