import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Header, Table } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function Promotions() {
  const [promotions, setPromotions] = useState([])

  const getData = () => {
    request(true).get('/promotions').then(e => setPromotions(e.data))
  }

  useEffect(() => {
    getData()
  }, [])

  const deletePromotion = (id) => {
    if (!window.confirm('Delete?')) return
    request(true).delete(`/promotions/${id}`).then(() => {
      getData()
    })
  }

  return (
    <Admin>
      <Header>Promotions</Header>
      <Link to="/promotions/new">
        <Button color="green">New</Button>
      </Link>
      <Table>
        <Table.Header>
          <Table.Row>
            <th>Key</th>
            <th>Title</th>
            <th>Actions</th>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            promotions.map(p => {
              return (
                <Table.Row key={p._id}>
                  <td>{p.web_position_key}</td>
                  <td>{p.title}</td>
                  <td>
                    <Link to={`/promotions/${p._id}`}>
                      <Button>Edit</Button>
                    </Link>
                    <Button onClick={() => deletePromotion(p._id)} color="red">Delete</Button>
                  </td>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </Admin>
  )
}
