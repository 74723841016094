import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Input, Label, Table } from 'semantic-ui-react'
import request from '../../helpers/request'
import ReactModal from 'react-modal'
import { useMemo } from 'react'

export default function VariablesList() {
    const [variables, setVariables] = useState([])

    useEffect(() => {
        request(true).get('/settings').then(e => {
            setVariables(e.data)
        })
    }, [])

    return (
        <div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Public?</th>
                        <th>Actions</th>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        variables.map(variable => {
                            return (<VariableItem key={`variable-${variable._id}`} variable={variable} />)
                        })
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

function VariableItem({ variable }) {
    const [showModal, setShowModal] = useState(false)
    const [newValue, setNewValue] = useState(typeof variable.value !== "string" ? JSON.stringify(variable.value) : variable.value)

    const type = useMemo(() => {
        const t = typeof variable.value

        if (t === "object") {
            return "text"
        } else if (t === "number") {
            return "number"
        } else if (t === "string") {
            return "text"
        }
    }, [variable.value])

    const getValue = (forDisplay = false) => {
        const t = typeof variable.value
        if (t === "object") {
            if (forDisplay) return newValue
            return JSON.parse(newValue)
        }

        if (t === "number") {
            return Number(newValue)
        }

        if (t === "string") {
            return String(newValue)
        }

        if (t === "boolean") {
            if (forDisplay) {
                if (newValue === "true") {
                    return "Yes"
                } else {
                    return "No"
                }
            }
            return newValue === "true"
        }
    }

    const submitUpdate = () => {
        const c = window.confirm('Are you sure you want to update? There is NO VALIDATION. ENSURE THE DATA IS CORRECT.')
        if (c) {
            request(true).patch(`/settings/${variable._id}`, { value: getValue() }).then(e => {
                alert(`Request: ${e.statusText}`)
            }).catch(e => {
                alert(`Request: ${e.response.data || e.response.statusText}`)
            })
        }
    }

    return [
        <Table.Row>
            <td>{variable.key}</td>
            <td>{getValue(true)}</td>
            <td>{variable.public ? <Label color="green">Yes</Label> : <Label color="red">No</Label>}</td>
            <td>
                <Button color="green" onClick={() => setShowModal(true)}>Update</Button>
            </td>
        </Table.Row>,
        <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={{ content: { maxWidth: 320, left: 'calc(50% - (320px / 2))', height: '75vh' } }}>
            <Input type={type} defaultValue={JSON.stringify(variable.value)} value={newValue} onChange={e => setNewValue(e.target.value)}></Input>
            <br />
            <Button color="red" onClick={() => submitUpdate()}>Update</Button>
        </ReactModal>
    ]
}