import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Header, Table } from 'semantic-ui-react'
import request from '../helpers/request'
import Admin from '../layouts/admin/Admin'

export default function Coupons() {
  const [coupons, setCoupons] = useState([])
  const getCoupons = () => {
    request(true).get('/coupons').then(e => { setCoupons(e.data) })
  }
  useEffect(() => {
    getCoupons()
  }, [])
  const deleteCoupon = (id) => {
    if (!window.confirm('Delete coupon?')) return
    request(true).delete(`/coupons/${id}`).then(() => getCoupons())
  }
  return (
    <Admin>
      <Header>Coupons</Header>
      <Link to="/coupons/new"><Button color="green">New</Button></Link>
      <Table>
        <Table.Header>
          <Table.Row>
            <th>Coupon</th>
            <th>Amount (Type)</th>
            <th>Quantity Available</th>
            <th>Actions</th>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            coupons.map(coupon => {
              return (
                <Table.Row>
                  <td>{coupon.code}</td>
                  <td>{coupon.discount_amount.amount} ({coupon.discount_amount.type})</td>
                  <td>{coupon.quantity_available}/{coupon.quantity_used + coupon.quantity_available}</td>
                  <td>
                    <Link to={`/coupons/${coupon._id}`}>
                      <Button color="green">Edit</Button>
                    </Link>
                    <Button onClick={() => deleteCoupon(coupon._id)} color="red">Delete</Button>
                  </td>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
    </Admin>
  )
}
