import React from 'react';
import Admin from '../layouts/admin/Admin';
import LoginComponent from '../components/login/login';

export default function Login () {
    return (
        <Admin>
            <LoginComponent/>
        </Admin>
    )
}
