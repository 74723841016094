import React from 'react'
import { useRouteMatch } from 'react-router'
import { Form } from 'semantic-ui-react'
import Title from '../components/title/title'
import Admin from '../layouts/admin/Admin'
import ImageManager from '../components/image-manager/image-manager'
import { useState } from 'react'
import { useEffect } from 'react'
import request from '../helpers/request'

export default function EditBid() {
  const match = useRouteMatch('/bids/:id')
  const type = match.params.id === 'new' ? 'New' : 'Update'

  const [currentBid, setCurrentBid] = useState({})

  useEffect(() => {
    if (type === "Update") {
      request(true).get(`/bids/${match.params.id}`).then(e => setCurrentBid(e.data))
    }
  }, [match.params.id, type])

  const saveData = () => {
    if (type === 'New') {
      request(true).post('/bids', currentBid).then(e => {
        if (e.status === 201) return window.alert('Created')
        window.alert(e.statusText)
      })
    }

    if (type === 'Update') {
      request(true).patch(`/bids/${match.params.id}`, currentBid).then(e => {
        window.alert(e.statusText)
      })
    }
  }

  return (
    <Admin>
      <Title>{type} Bid</Title>
      <div>
        <Form onSubmit={() => saveData()}>
          <h2>Basic</h2>
          <Form.Field>
            <label>Name</label>
            <input type="text" placeholder="Name" onChange={e => setCurrentBid({ ...currentBid, name: e.target.value })} defaultValue={currentBid.name} required />
          </Form.Field>
          <Form.Field>
            <label>Website</label>
            <input type="text" placeholder="Website" onChange={e => setCurrentBid({ ...currentBid, website: e.target.value })} defaultValue={currentBid.website} required />
          </Form.Field>
          <Form.Field>
            <label>Phone Number</label>
            <input type="text" placeholder="Phone" onChange={e => setCurrentBid({ ...currentBid, phone: e.target.value })} defaultValue={currentBid.phone} required />
          </Form.Field>
          {
            type === 'Update' ? <Form.Field>
              <label>Logo (Single Image)</label>
              <ImageManager endpoint={`/bids/${match.params.id}/logo`} />
            </Form.Field> : null
          }

          <h2>Public Details</h2>
          <Form.Field>
            <label>Name</label>
            <input type="text" placeholder="Name" onChange={(e) => setCurrentBid({ ...currentBid, contact: { ...currentBid.contact, name: e.target.value } })} defaultValue={currentBid.contact ? currentBid.contact.name : null} required />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input type="text" placeholder="Email" onChange={(e) => setCurrentBid({ ...currentBid, contact: { ...currentBid.contact, email: e.target.value } })} defaultValue={currentBid.contact ? currentBid.contact.email : null} required />
          </Form.Field>
          <Form.Field>
            <label>Phone</label>
            <input type="text" placeholder="Phone" onChange={(e) => setCurrentBid({ ...currentBid, contact: { ...currentBid.contact, phone: e.target.value } })} defaultValue={currentBid.contact ? currentBid.contact.phone : null} required />
          </Form.Field>
          {
            type === 'Update' ? <Form.Field>
              <label>Images (Multiple Images)</label>
              <ImageManager endpoint={`/bids/${match.params.id}/images`} />
            </Form.Field> : null
          }
          

          <h2>Social</h2>
          <Form.Field>
            <label>Link</label>
            <input type="text" placeholder="Link" onChange={(e) => setCurrentBid({ ...currentBid, social: { ...currentBid.social, link: e.target.value } })} defaultValue={currentBid.social ? currentBid.social.link : null} required />
          </Form.Field>
          <Form.Field>
            <label>Icon</label>
            <input type="text" placeholder="Icon" onChange={(e) => setCurrentBid({ ...currentBid, social: { ...currentBid.social, icon: e.target.value } })} defaultValue={currentBid.social ? currentBid.social.icon : null} required />
          </Form.Field>

          <h2>Address</h2>
          <Form.Field>
            <label>Line 1</label>
            <input type="text" placeholder="Line 1" onChange={(e) => setCurrentBid({ ...currentBid, address: { ...currentBid.address, line1: e.target.value } })}  defaultValue={currentBid.address ? currentBid.address.line1 : null} required />
          </Form.Field>
          <Form.Field>
            <label>Town</label>
            <input type="text" placeholder="Town" onChange={(e) => setCurrentBid({ ...currentBid, address: { ...currentBid.address, town: e.target.value } })}  defaultValue={currentBid.address ? currentBid.address.town : null} required />
          </Form.Field>
          <Form.Field>
            <label>Postcode</label>
            <input type="text" placeholder="Postcode" onChange={(e) => setCurrentBid({ ...currentBid, address: { ...currentBid.address, postcode: e.target.value } })}  defaultValue={currentBid.address ? currentBid.address.postcode : null} required />
          </Form.Field>

          <h2>Geo</h2>
          <Form.Field>
            <label>Latitude</label>
            <input type="number" step="any" placeholder="Latitude" onChange={e => setCurrentBid({ ...currentBid, geo: { ...(currentBid.geo || {type:'Point'}), coordinates: [Number(e.target.value), (currentBid.geo || {type:'Point',coordinates:[]}).coordinates[1]] } })} defaultValue={currentBid.geo ? currentBid.geo.coordinates[0] : null} required />
          </Form.Field>
          <Form.Field>
            <label>Longitude</label>
            <input type="number" step="any" placeholder="Longitude" onChange={e => setCurrentBid({ ...currentBid, geo: { ...(currentBid.geo || {type:'Point'}), coordinates: [(currentBid.geo || {type:'Point',coordinates:[]}).coordinates[0], Number(e.target.value)] } })} defaultValue={currentBid.geo ? currentBid.geo.coordinates[1] : null} required />
          </Form.Field>

          <Form.Button color="green">Save</Form.Button>

        </Form>
      </div>
    </Admin>
  )
}
