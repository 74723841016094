import React, { useCallback, useEffect } from 'react';
import request from '../../helpers/request';
import { useHistory } from 'react-router-dom';
import {Input,Button} from 'semantic-ui-react'


const EditTag = (props) => {
    const [formState, setFormState] = React.useState({
        name: '',
        icon: '',
    });

    const onChange = useCallback((event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }, [formState, setFormState]);

    const history = useHistory();

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        if (props.tagId === null) {
            request(true).post('/tags', {
                name: formState.name,
                icon: formState.icon
            }).then(r => {
                history.push('/tags');
            }).catch(e => {
                console.log(e.response);
            });
        } else {
            request(true).patch(`/tags/${props.tagId}`, {
                name: formState.name,
                icon: formState.icon,
            }).then(r => {
                history.push('/tags');
            }).catch(e => {
                console.log(e.response);
            })
        }
    }, [props.tagId, formState, history]);

    useEffect(() => {
        setFormState({
            name: props.tag.name,
            icon: props.tag.icon
        });
    }, [setFormState, props.tag]);

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-1 grid-gap-1">
                <label>Name</label>
                <Input name="name" type="text" placeholder="Name" value={ formState.name } onChange={ onChange }/>
                <label>Icon</label>
                <Input name="icon" type="text" placeholder="Icon" value={ formState.icon } onChange={ onChange }/>
                <Button>{ props.tagId ? 'Update' : 'Create' }</Button>
            </div>
        </form>
    );
}

export default EditTag;
