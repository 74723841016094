import React, { useCallback, useEffect } from 'react';
import request from '../../../helpers/request';
import ReactMde from 'react-mde'
import ReactMarkdown from 'react-markdown'
import {Input, Button, Segment, Grid, Header, Form} from 'semantic-ui-react'
import "react-mde/lib/styles/css/react-mde-all.css";

const EditProduct = (props) => {
    const [formState, setFormState] = React.useState({
        title: '',
        price: '',
        discountDecimal: '',
        bagFee: '',
        boxSize: '',
        customisation: []
    });

    const [variant, setVariant] = React.useState({
        customisation: [],
        title: '',
        price: '',
        discountDecimal: '',
        bagFee: '',
        boxSize: '',
    });

    const [markdownPage, setMarkdownPage] = React.useState('write')

    const onChange = useCallback((event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    }, [formState, setFormState]);

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        request(true).get(`/products/${props.product._id}`).then(r => {
            let data = r.data;

            if (props.variantId) {
                let vIndex = null;

                Promise.all(data.products.map((item, index) => {
                    if (item._id === props.variantId) {
                        vIndex = index;
                    }

                    return item;
                })).then(() => {
                    let copy = data;
                    copy.products[vIndex] = formState;
                    copy.images = copy.images.map(a => a._id)
                    copy.videos = copy.videos.map(a => a._id)

                    request(true).patch(`/products/${props.product._id}`, copy).then(r => {
                        console.log(r);
                        alert(r.statusText)
                    }).catch(e => {
                        alert(e.response.data)
                        console.log(e.response);
                    });
                });

            } else {
                data.products.push(formState);
                data.images = data.images.map(a => a._id)
                data.videos = data.videos.map(a => a._id)

                request(true).patch(`/products/${props.product._id}`, data).then(r => {
                    console.log(r);
                    alert(r.statusText)
                }).catch(e => {
                    console.log(e.response);
                    alert(e.response.data)
                });
            }
        });
    }, [formState, props.product._id, props.variantId]);

    useEffect(() => {
        if (props.variantId === null) {
            setVariant({
                customisation: [],
                title: '',
                price: '',
                discountDecimal: '',
                bagFee: '',
                boxSize: '',
            });
        } else {
            props.product.products.map(item => {
                if (item._id === props.variantId) {
                    setVariant(item);
                }

                return item;
            });
        }
    }, [props.product, props.variantId]);

    useEffect(() => {
        if (variant === null) {

        } else {
            setFormState(variant)
        }
    }, [variant])

    const addCustomisation = useCallback((e) => {
        e.preventDefault()
        let items = [].concat(formState.customisation);
        items.push({
            title: '',
            options: [],
        });

        setFormState({
            ...formState,
            customisation: items
        });
    }, [formState, setFormState])

    return (
        <form onSubmit={onSubmit}>
            <div>
                <Segment>
                    <Header>Basic Information</Header>
                    <Form>
                        <Grid columns="2">
                            <Grid.Column>
                                    <Form.Field>
                                        <label>Title</label>
                                        <Input name="title" type="text" placeholder="Name" value={ formState.title } onChange={ onChange }/>
                                    </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Price</label>
                                    <Input name="price" type="number" placeholder="Price" value={ formState.price } onChange={ onChange }/>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Discount (0.00)</label>
                                    <Input name="discountDecimal" type="number" placeholder="Discount" value={ formState.discountDecimal } onChange={ onChange }/>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Bag Fee (0.00)</label>
                                    <Input name="bagFee" type="number" placeholder="Bag Fee" value={ formState.bagFee } onChange={ onChange }/>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Bag Size</label>
                                    <Input name="boxSize" type="text" placeholder="Box Size" value={ formState.boxSize } onChange={ onChange }/>
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Segment>
                <Segment>
                    <Header>Descriptions</Header>
                    <Grid columns="2">
                        <Grid.Column>
                            <label>Short Description</label>
                            <ReactMde
                                value={ formState.shortDescription }
                                onChange={e => onChange({
                                    target: {
                                        name: 'shortDescription',
                                        value: e
                                    }
                                })}
                                selectedTab={markdownPage}
                                onTabChange={setMarkdownPage}
                                generateMarkdownPreview={(md) => Promise.resolve(<ReactMarkdown source={md} />)}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label>Long Description</label>
                            <ReactMde
                                value={ formState.longDescription }
                                onChange={e => onChange({
                                    target: {
                                        name: 'longDescription',
                                        value: e
                                    }
                                })}
                                selectedTab={markdownPage}
                                onTabChange={setMarkdownPage}
                                generateMarkdownPreview={(md) => Promise.resolve(<ReactMarkdown source={md} />)}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
            <Segment>
                <Header>Customisations</Header>
                <Button color="green" onClick={ addCustomisation }>Add New Customisation</Button>
                { formState.customisation.map((item, index) => {
                    return (
                        <Customisation key={ item._id ? item._id : `customisation-${index}` } item={item} setFormState={setFormState} formState={formState} index={ index }/>
                    )
                })}
            </Segment>
            <Button>{ props.variantId ? 'Update' : 'Create' }</Button>
        </form>
    );
}

const Customisation = ({ formState, setFormState, item, index, ...props}) => {
    const onChange = useCallback(event => {
        let items = [].concat(formState.customisation);
        items[index][event.target.name] = event.target.value;

        setFormState({
            ...formState,
            customisation: items
        });
    }, [index, setFormState, formState]);

    const add = useCallback((e) => {
        e.preventDefault()
        let items = [].concat(formState.customisation)
        items[index].options.push({
            name: '',
            priceModifier: ''
        });

        setFormState({
            ...formState,
            customisation: items
        })
    }, [formState, setFormState, index])

    const remove = (index) => {
        let items = [].concat(formState.customisation)

        items.splice(index, 1)

        setFormState({
            ...formState,
            customisation: items
        })
    }

    const copy = (e, index) => {
        e.preventDefault()
        let items = [].concat(formState.customisation)

        let copy = JSON.parse(JSON.stringify(formState.customisation[index]))

        copy._id = undefined
        copy.options.map(o => o._id = undefined)

        items.push(copy)

        setFormState({
            ...formState,
            customisation: items
        })
    }

    return (
        <Segment className='customisation' color="orange">
            <Grid columns="1">
                <Grid.Column>
                    <Form>
                        <Form.Field>
                            <label>Title</label>
                            <Input name="title" placeholder="Title" value={ item.title } onChange={ onChange }/>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <Button color="green" onClick={ add }>Add New Option</Button>
                </Grid.Column>
                <Grid.Column>
                    <Grid columns="2">
                        { item.options.map((item, vIndex) => {
                            return (
                                <Grid.Column>
                                    <CustomisationItem key={ item._id ? item._id : `customisation-${index}-item-${vIndex}` } item={item} setFormState={setFormState} formState={formState} index={ vIndex } variantIndex={ index }/>
                                </Grid.Column>
                            )
                        })}
                    </Grid>
                </Grid.Column>
                <Grid.Column>
                    {item._id ? <Button onClick={e => copy(e, index)} color="orange" icon="copy" /> : null}
                    <Button color="red" onClick={ (e) => { e.preventDefault(); remove(index) } }>Delete Customisation</Button>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

const CustomisationItem = ({ formState, setFormState, item, index, variantIndex, ...props}) => {
    const onChange = useCallback(event => {
        let items = [].concat(formState.customisation);
        items[variantIndex].options[index][event.target.name] = event.target.value;

        setFormState({
            ...formState,
            customisation: items
        });
    }, [formState, variantIndex, index, setFormState]);

    const remove = (e) => {
        e.preventDefault()
        let items = [].concat(formState.customisation)

        items[variantIndex].options.splice(index, 1)

        setFormState({
            ...formState,
            customisation: items
        })
    }

    return (
        <Segment>
            <Grid columns="2">
                <Grid.Column>
                    <label>Option Name</label>
                    <Input name="name" type="text" placeholder="Name" value={ item.name } onChange={ onChange }/>
                </Grid.Column>
                <Grid.Column>
                    <label>Price Modifier</label>
                    <Input name="priceModifier" type="number" placeholder="Price Modifier" value={ item.priceModifier } onChange={ onChange }/>
                </Grid.Column>
                <Grid.Column>
                    <Button onClick={ remove } color="red">Remove this option</Button>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default EditProduct;
