import React, { useEffect } from 'react';

const Radios = (props) => {
    const [selected, setSelected] = React.useState();

    const onChange = (value) => {
        setSelected(value);
        props.onChange(props.name, value);
    }

    useEffect(() => {
        if (selected !== props.value) {
            setSelected(props.value);
        }
    }, [selected, props.value]);

    return (
        <div className="radio-wrapper">
            { props.options.map((item, index) => {
                return (
                    <Radio key={`${props.name}-${index}`} name={props.name} label={ item.label } value={ item.value } selected={ selected === item.value } onChange={onChange}/>
                )
            })}
        </div>
    );
}

const Radio = (props => {
    const onChange = (event) => {
        props.onChange(event.target.value);
    }

    return (
        <div className="radio-item">
            <label>{ props.label }</label>
            <input type="radio" name={props.name} value={props.value} onChange={onChange} checked={ props.selected }/>
        </div>
    )
})

export default Radios;
