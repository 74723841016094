import React from 'react';
import Product from './product';
import './style.scss';

const Products = (props) => {
    return (
        <div className="products">
            { props.products.map((item, index) => {
                return (
                    <Product vendor={props.vendor} product={item} key={`product-${index}`}/>
                )
            })}
        </div>
    );
}

export default Products;
